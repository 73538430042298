import React, { useState, useEffect } from 'react';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Checkbox,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton,
  Autocomplete,
  CircularProgress,
  Switch,
  FormControlLabel,
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import { useAuth } from '../contexts/AuthContext';
import { 
  fetchDistinctValues,
  fetchLocation,
  fetchCustomerCategories 
} from '../api/roleManagement';

const RoleDialogForm = ({
  open,
  onClose,
  onSave,
  initialData,
  companies = [],
  availableApps = [],
  availableRoles = [],
  carBrands = [],
  locations = [], // Change this line
  customerCategories = [],
  registrationFields = [],
  filterTypes = [],
  operators = [],
}) => {
  const [formData, setFormData] = useState({
    id: null,
    company: '',
    role_name: '',
    notify: false,
    brand: [],
    location: [], // Change this line
    clients_category: [],
    client_metadata: [],
    app_access: [],
    custom_filters: [],
  });

  const [error, setError] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const [distinctValues, setDistinctValues] = useState({});
  const { userRole, appAccess } = useAuth();
  const permissions = appAccess['user_management'] || {};
  const canView = permissions.can_view || userRole === 'superadmin';
  const canCreate = permissions.can_create || userRole === 'superadmin';
  const canEdit = permissions.can_edit || userRole === 'superadmin';
  const canDelete = permissions.can_delete || userRole === 'superadmin';

  // Define price fields that use standard input (TextField)
  const priceFields = ['saleprice']; // Adjust this field name as per your data model

  // Add new state variables for company-specific data
  const [companyLocations, setCompanyLocations] = useState([]);
  const [companyCategories, setCompanyCategories] = useState([]);

  useEffect(() => {
    setIsLoading(true);
    if (initialData) {
      setFormData({
        ...initialData,
        brand: initialData.brand?.map((b) => b.id) || [],
        location: initialData.location?.map((l) => l.id) || [], // Add this line
        clients_category: initialData.clients_category?.map((c) => c.id) || [],
        client_metadata: initialData.client_metadata || [],
        custom_filters: initialData.custom_filters || [],
      });
    } else if (companies.length === 1) {
      setFormData((prevData) => ({
        ...prevData,
        company: companies[0].id,
      }));
    }
    setIsLoading(false);
  }, [initialData, companies]);

  useEffect(() => {
    const fetchCompanyDependentData = async () => {
      if (formData.company) {
        try {
          console.log('Fetching data for company:', formData.company);
          // Fetch locations and categories for the selected company
          const [locationsData, categoriesData] = await Promise.all([
            fetchLocation(formData.company),
            fetchCustomerCategories(formData.company)
          ]);
          
          console.log('Fetched locations:', locationsData);
          console.log('Fetched categories:', categoriesData);

          // Update the company-specific data
          setCompanyLocations(locationsData);
          setCompanyCategories(categoriesData);

          // Clear selections if the selected items are not available in the new company
          setFormData(prev => {
            const newFormData = {
              ...prev,
              location: prev.location.filter(locId => 
                locationsData.some(loc => loc.id === locId)
              ),
              clients_category: prev.clients_category.filter(catId => 
                categoriesData.some(cat => cat.id === catId)
              ),
              custom_filters: prev.custom_filters.map(filter => ({
                ...filter,
                value: Array.isArray(filter.value) ? [] : (typeof filter.value === 'string' ? filter.value.split(',') : '')
              }))
            };
            console.log('Updated formData:', newFormData);
            return newFormData;
          });
        } catch (error) {
          console.error('Error fetching company-dependent data:', error);
        }
      } else {
        // Reset company-specific data when no company is selected
        setCompanyLocations([]);
        setCompanyCategories([]);
      }
    };

    fetchCompanyDependentData();
  }, [formData.company]);

  useEffect(() => {
    console.log('Current custom filters:', formData.custom_filters);
  }, [formData.custom_filters]);

  const fetchDistinctValuesForField = async (field) => {
    if (!formData.company || !field) return;
    
    const cacheKey = `${formData.company}-${field}`;
    if (distinctValues[cacheKey]) return;

    try {
      console.log(`Fetching distinct values for field ${field} and company ${formData.company}`);
      const values = await fetchDistinctValues(field, formData.company);
      setDistinctValues(prev => ({ 
        ...prev, 
        [cacheKey]: values || [] 
      }));
      console.log(`Received distinct values for ${field}:`, values);
    } catch (error) {
      console.error(`Error fetching distinct values for ${field}:`, error);
      setDistinctValues(prev => ({ 
        ...prev, 
        [cacheKey]: [] 
      }));
    }
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    if (name === 'company') {
      // Clear all company-specific data
      setFormData(prev => ({
        ...prev,
        [name]: value,
        location: [],
        clients_category: [],
        custom_filters: []  // Clear all filters instead of just their values
      }));
      // Clear distinct values for the previous company
      setDistinctValues({});
    } else {
      setFormData(prev => ({
        ...prev,
        [name]: value
      }));
    }
  };

  const handleMultiSelect = (name, value) => {
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleAppPermissionChange = (appId, field, value) => {
    if (!canEdit) return;
    setFormData((prevData) => {
      const appAccessIndex = prevData.app_access.findIndex((a) => a.app === appId);
      if (appId === 'settings') {
        const newPermissions = {
          can_view: value,
          can_create: value,
          can_edit: value,
          can_delete: value,
          apply_filter: value,
          notify: value,
        };
        if (appAccessIndex === -1) {
          return {
            ...prevData,
            app_access: [...prevData.app_access, { app: appId, ...newPermissions }],
          };
        } else {
          const newAppAccess = [...prevData.app_access];
          newAppAccess[appAccessIndex] = {
            ...newAppAccess[appAccessIndex],
            ...newPermissions,
          };
          return {
            ...prevData,
            app_access: newAppAccess,
          };
        }
      } else {
        if (appAccessIndex === -1) {
          return {
            ...prevData,
            app_access: [...prevData.app_access, { app: appId, [field]: value }],
          };
        } else {
          const newAppAccess = [...prevData.app_access];
          newAppAccess[appAccessIndex] = {
            ...newAppAccess[appAccessIndex],
            [field]: value,
          };
          return {
            ...prevData,
            app_access: newAppAccess,
          };
        }
      }
    });
  };

  const handleAddClientMetadata = () => {
    if (!canEdit) return;
    setFormData((prevData) => ({
      ...prevData,
      client_metadata: [...prevData.client_metadata, { field: '', condition: 'equal', value: '' }],
    }));
  };

  const handleRemoveClientMetadata = (index) => {
    if (!canEdit) return;
    setFormData((prevData) => ({
      ...prevData,
      client_metadata: prevData.client_metadata.filter((_, i) => i !== index),
    }));
  };

  const handleClientMetadataChange = (index, field, value) => {
    if (!canEdit) return;
    setFormData((prevData) => ({
      ...prevData,
      client_metadata: prevData.client_metadata.map((item, i) => {
        if (i === index) {
          if (field === 'field') {
            return { ...item, [field]: value, value: '' };
          }
          return { ...item, [field]: value };
        }
        return item;
      }),
    }));
  };

  const handleAddCustomFilter = () => {
    if (!canEdit) return;
    setFormData((prevData) => ({
      ...prevData,
      custom_filters: [
        ...prevData.custom_filters,
        { field: '', filter_type: '', operator: '', value: '' },
      ],
    }));
  };

  const handleRemoveCustomFilter = (index) => {
    if (!canEdit) return;
    setFormData((prevData) => ({
      ...prevData,
      custom_filters: prevData.custom_filters.filter((_, i) => i !== index),
    }));
  };

  const handleCustomFilterChange = async (index, field, value) => {
    if (!canEdit) return;
    
    if (field === 'field' && !priceFields.includes(value)) {
      await fetchDistinctValuesForField(value);
    }

    setFormData((prevData) => ({
      ...prevData,
      custom_filters: prevData.custom_filters.map((filter, i) => {
        if (i === index) {
          const updatedFilter = { ...filter, [field]: value };
          if (field === 'field') {
            const selectedFilterType = filterTypes.find((ft) => ft.value === value);
            updatedFilter.filter_type = selectedFilterType ? selectedFilterType.value : '';
            updatedFilter.operator = '';
            updatedFilter.value = '';
          }
          if (field === 'value' && Array.isArray(value)) {
            updatedFilter.value = value.join(',');
          }
          return updatedFilter;
        }
        return filter;
      }),
    }));
  };

  const getOperatorOptions = () => operators;

  const handleSave = () => {
    if (!canCreate && !initialData) {
      setError('Non hai il permesso di creare nuovi ruoli.');
      return;
    }
    if (!canEdit && initialData) {
      setError('Non hai il permesso di modificare i ruoli.');
      return;
    }
    if (!formData.company || !formData.role_name) {
      setError('Per favore, compila tutti i campi obbligatori');
      return;
    }
    setError('');

    const dataToSave = {
      ...formData,
      brand: formData.brand.map((id) => carBrands.find((brand) => brand.id === id)),
      location: formData.location.map((id) => locations.find((loc) => loc.id === id)), // Add this line
      clients_category: formData.clients_category.map((id) =>
        customerCategories.find((category) => category.id === id)
      ),
    };

    onSave(dataToSave);
  };

  const getAvailableFields = (currentIndex) => {
    const selectedFields = formData.client_metadata
      .map((metadata) => metadata.field)
      .filter(Boolean);

    const initialSelectedFields = initialData?.client_metadata
      ? initialData.client_metadata.map((metadata) => metadata.field).filter(Boolean)
      : [];

    const usedFields = [...new Set([...selectedFields, ...initialSelectedFields])];

    return registrationFields.filter((field) => {
      return (
        !usedFields.includes(field.id) ||
        (currentIndex !== undefined && field.id === formData.client_metadata[currentIndex]?.field)
      );
    });
  };

  const getFieldOptions = (fieldId) => {
    const field = registrationFields.find((f) => f.id === fieldId);
    return field && field.options ? field.options.split(',').map((option) => option.trim()) : [];
  };

  if (!canView) {
    return (
      <Dialog open={open} onClose={onClose}>
        <DialogContent>
          <Typography>Non hai il permesso di visualizzare questo contenuto.</Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} color="primary">
            Chiudi
          </Button>
        </DialogActions>
      </Dialog>
    );
  }

  if (isLoading) {
    return (
      <Dialog open={open} onClose={onClose}>
        <DialogContent>
          <CircularProgress />
        </DialogContent>
      </Dialog>
    );
  }

  return (
    <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
      <DialogTitle>{initialData ? 'Modifica Ruolo' : 'Aggiungi Ruolo'}</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Compila i campi sottostanti per {initialData ? 'modificare' : 'aggiungere'} un ruolo.
        </DialogContentText>
        {error && <Typography color="error">{error}</Typography>}

        {/* Company Selection */}
        <FormControl fullWidth margin="dense">
          <InputLabel id="company-label">Azienda</InputLabel>
          <Select
            labelId="company-label"
            name="company"
            value={formData.company || ''}
            label="Azienda"
            onChange={handleChange}
            required
            disabled={companies.length === 1 || !canEdit}
          >
            {companies.map((company) => (
              <MenuItem key={company.id} value={company.id}>
                {company.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        {/* Role Name */}
        <FormControl fullWidth margin="dense">
          <TextField
            label="Nome del ruolo"
            name="role_name"
            value={formData.role_name || ''}
            onChange={handleChange}
            required
            disabled={!canEdit}
          />
        </FormControl>

        {/* Location Selection */}
        <FormControl fullWidth margin="dense">
          <Autocomplete
            multiple
            id="location"
            options={companyLocations}
            getOptionLabel={(option) => `${option?.citta}${option?.inidirizzo ? ` - ${option.inidirizzo}` : ''}`}
            value={
              (formData.location || [])
                .map((id) => companyLocations?.find((loc) => loc?.id === id))
                .filter(Boolean)
            }
            onChange={(event, newValue) => {
              console.log('Location onChange:', newValue);
              if (canEdit) handleMultiSelect('location', newValue.map((item) => item.id));
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="outlined"
                label="Sedi"
                placeholder="Cerca e seleziona le sedi"
              />
            )}
            disabled={!canEdit}
          />
        </FormControl>

        {/* Customer Categories */}
        <FormControl fullWidth margin="dense">
          <Autocomplete
            multiple
            id="customer-categories"
            options={companyCategories}
            getOptionLabel={(option) => option.name}
            value={
              (formData.clients_category || [])
                .map((id) => companyCategories?.find((cat) => cat.id === id))
                .filter(Boolean)
            }
            onChange={(event, newValue) => {
              console.log('Category onChange:', newValue);
              if (canEdit) handleMultiSelect('clients_category', newValue.map((item) => item.id));
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="outlined"
                label="Categorie Clienti"
                placeholder="Cerca e seleziona categorie"
              />
            )}
            disabled={!canEdit}
          />
        </FormControl>

        {/* Custom Filters */}
        <Typography variant="h6" style={{ marginTop: '20px', marginBottom: '10px' }}>
          Filtri Auto
        </Typography>
        {formData.custom_filters.map((filter, index) => (
          <div
            key={index}
            style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}
          >
            <FormControl style={{ marginRight: '10px', flexGrow: 1 }}>
              <InputLabel>Campo</InputLabel>
              <Select
                value={filter.field}
                onChange={(e) => handleCustomFilterChange(index, 'field', e.target.value)}
                disabled={!canEdit}
              >
                {filterTypes.map((ft) => (
                  <MenuItem key={ft.value} value={ft.value}>
                    {ft.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl style={{ marginRight: '10px', flexGrow: 1 }}>
              <InputLabel>Operatore</InputLabel>
              <Select
                value={filter.operator}
                onChange={(e) => handleCustomFilterChange(index, 'operator', e.target.value)}
                disabled={!canEdit}
              >
                {getOperatorOptions().map((op) => (
                  <MenuItem key={op.value} value={op.value}>
                    {op.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            {/* Render value input based on whether the field is a price field */}
            {priceFields.includes(filter.field) ? (
              <TextField
                style={{ flexGrow: 1 }}
                label="Valore"
                type="number"
                value={filter.value || ''}
                onChange={(e) => handleCustomFilterChange(index, 'value', e.target.value)}
                disabled={!canEdit}
              />
            ) : (
              <Autocomplete
                multiple
                style={{ flexGrow: 1 }}
                options={distinctValues[`${formData.company}-${filter.field}`] || []}
                value={
                  typeof filter.value === 'string' 
                    ? filter.value.split(',').filter(Boolean)
                    : Array.isArray(filter.value)
                      ? filter.value
                      : []
                }
                onChange={(event, newValue) => handleCustomFilterChange(index, 'value', newValue)}
                renderInput={(params) => <TextField {...params} label="Valori" />}
                disabled={!canEdit}
              />
            )}
            {canEdit && (
              <IconButton onClick={() => handleRemoveCustomFilter(index)}>
                <DeleteIcon />
              </IconButton>
            )}
          </div>
        ))}
        {canEdit && (
          <Button
            startIcon={<AddIcon />}
            onClick={handleAddCustomFilter}
            style={{ marginTop: '10px' }}
          >
            Aggiungi Filtro
          </Button>
        )}

        {/* App Permissions */}
        <Typography variant="h6" style={{ marginTop: '20px', marginBottom: '10px' }}>
          Permessi per App
        </Typography>
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>App</TableCell>
                <TableCell align="center">Visualizza</TableCell>
                <TableCell align="center">Crea</TableCell>
                <TableCell align="center">Modifica</TableCell>
                <TableCell align="center">Elimina</TableCell>
                <TableCell align="center">Applica filtri</TableCell>
                <TableCell align="center">Notifiche</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {availableApps.map(([appId, appName]) => {
                const appAccess = formData.app_access.find((a) => a.app === appId) || {};
                const isSettingsApp = appId === 'settings';
                return (
                  <TableRow key={appId}>
                    <TableCell component="th" scope="row">
                      {appName}
                    </TableCell>
                    <TableCell align="center">
                      <Checkbox
                        checked={appAccess.can_view || false}
                        onChange={() => handleAppPermissionChange(appId, 'can_view', !appAccess.can_view)}
                        disabled={
                          !canEdit || (isSettingsApp && appAccess.can_view !== appAccess.can_create)
                        }
                      />
                    </TableCell>
                    <TableCell align="center">
                      <Checkbox
                        checked={appAccess.can_create || false}
                        onChange={() => handleAppPermissionChange(appId, 'can_create', !appAccess.can_create)}
                        disabled={
                          !canEdit || (isSettingsApp && appAccess.can_create !== appAccess.can_view)
                        }
                      />
                    </TableCell>
                    <TableCell align="center">
                      <Checkbox
                        checked={appAccess.can_edit || false}
                        onChange={() => handleAppPermissionChange(appId, 'can_edit', !appAccess.can_edit)}
                        disabled={
                          !canEdit || (isSettingsApp && appAccess.can_edit !== appAccess.can_view)
                        }
                      />
                    </TableCell>
                    <TableCell align="center">
                      <Checkbox
                        checked={appAccess.can_delete || false}
                        onChange={() => handleAppPermissionChange(appId, 'can_delete', !appAccess.can_delete)}
                        disabled={
                          !canEdit || (isSettingsApp && appAccess.can_delete !== appAccess.can_view)
                        }
                      />
                    </TableCell>
                    <TableCell align="center">
                      <Checkbox
                        checked={appAccess.apply_filter || false}
                        onChange={() =>
                          handleAppPermissionChange(appId, 'apply_filter', !appAccess.apply_filter)
                        }
                        disabled={
                          !canEdit || (isSettingsApp && appAccess.apply_filter !== appAccess.can_view)
                        }
                      />
                    </TableCell>
                    <TableCell align="center">
                      <Checkbox
                        checked={appAccess.notify || false}
                        onChange={() =>
                          handleAppPermissionChange(appId, 'notify', !appAccess.notify)
                        }
                        disabled={
                          !canEdit || (isSettingsApp && appAccess.notify !== appAccess.can_view)
                        }
                      />
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Annulla
        </Button>
        {(canCreate || (canEdit && initialData)) && (
          <Button onClick={handleSave} color="primary">
            Salva
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
};

export default RoleDialogForm;
