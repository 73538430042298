import React from 'react';
import { Grid, TextField, Button, FormControl, Select, MenuItem, Checkbox, ListItemText, Paper, Typography, InputLabel } from '@mui/material';
import { useAuth } from '../../../contexts/AuthContext';

const HolidayForm = ({
  holidayFormData,
  handleHolidayFormChange,
  handleHolidayFormSubmit,
  isEditingHoliday,
  services,
  locations = [],
}) => {
  const { userRole, appAccess } = useAuth();
  const permissions = appAccess['booking_settings'] || {};
  const setting_canCreate = permissions.can_create || userRole === 'superadmin';
  const setting_canEdit = permissions.can_edit || userRole === 'superadmin';
  const canSubmitForm = isEditingHoliday ? setting_canEdit : setting_canCreate;

  const formLocations = Array.isArray(holidayFormData.locations) 
    ? holidayFormData.locations 
    : [];

  return (
    <Paper elevation={3} style={{ padding: '20px', marginBottom: '20px' }}>
      <Typography variant="h6" gutterBottom>
        {isEditingHoliday ? 'Edit Holiday' : 'Add New Holiday'}
      </Typography>
      <form onSubmit={handleHolidayFormSubmit}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={4}>
            <TextField
              name="name"
              label="Holiday Name"
              value={holidayFormData.name}
              onChange={handleHolidayFormChange}
              fullWidth
              required
              disabled={!canSubmitForm}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <TextField
              name="start_date"
              label="Start Date"
              type="date"
              value={holidayFormData.start_date}
              onChange={handleHolidayFormChange}
              fullWidth
              required
              disabled={!canSubmitForm}
              InputLabelProps={{ shrink: true }}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <TextField
              name="end_date"
              label="End Date"
              type="date"
              value={holidayFormData.end_date}
              onChange={handleHolidayFormChange}
              fullWidth
              required
              disabled={!canSubmitForm}
              InputLabelProps={{ shrink: true }}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormControl fullWidth disabled={!canSubmitForm}>
              <InputLabel id="select-multiple-services-label">Select Services</InputLabel>
              <Select
                labelId="select-multiple-services-label"
                multiple
                name="services"
                value={holidayFormData.services || []}
                onChange={handleHolidayFormChange}
                renderValue={(selected) =>
                  selected
                    .map((serviceId) => {
                      const service = services.find((s) => s.id === serviceId);
                      return service ? service.name : '';
                    })
                    .join(', ')
                }
              >
                {services.map((service) => (
                  <MenuItem key={service.id} value={service.id}>
                    <Checkbox checked={(holidayFormData.services || []).indexOf(service.id) > -1} />
                    <ListItemText primary={service.name} />
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormControl fullWidth disabled={!canSubmitForm} required>
              <InputLabel id="select-multiple-locations-label">Select Cities</InputLabel>
              <Select
                labelId="select-multiple-locations-label"
                multiple
                name="locations"
                value={formLocations}
                onChange={handleHolidayFormChange}
                renderValue={(selected) =>
                  selected
                    .map((locationId) => {
                      const location = locations.find((l) => l.id === locationId);
                      return location ? location.citta : '';
                    })
                    .filter(Boolean)
                    .join(', ')
                }
                error={!Array.isArray(formLocations) || formLocations.length === 0}
              >
                {locations.map((location) => (
                  <MenuItem key={location.id} value={location.id}>
                    <Checkbox checked={formLocations.indexOf(location.id) > -1} />
                    <ListItemText primary={location.citta} />
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            {canSubmitForm && (
              <Button type="submit" variant="contained" color="primary">
                {isEditingHoliday ? 'Update Holiday' : 'Add Holiday'}
              </Button>
            )}
          </Grid>
        </Grid>
      </form>
    </Paper>
  );
};

export default HolidayForm;
