import React, { useState, useEffect, useRef, useCallback } from 'react';
import { useTheme, styled } from '@mui/material/styles';
import ReactMarkdown from 'react-markdown';
import { alpha } from '@mui/material/styles';
import {
  Container,
  Typography,
  Card,
  CardContent,
  CardMedia,
  Grid,
  Snackbar,
  CircularProgress,
  Chip,
  List,
  ListItem,
  Divider,
  Box,
  Paper,
  Avatar,
  IconButton,
  Tooltip,
  Fade,
  Zoom,
  Slide,
  useMediaQuery,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Select,
  MenuItem,
  Button,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  TextField,
  Table,
  TableBody,
  TableCell,
  TableRow,
} from '@mui/material';
import {
  Edit as EditIcon,
  Save as SaveIcon,
  Person as PersonIcon,
  SmartToy as AIIcon,
  DirectionsCar as CarIcon,
  CheckCircle as CheckIcon,
  Error as ErrorIcon,
  ThumbUp,
  ThumbDown,
  Comment as CommentIcon,
  ExpandMore as ExpandMoreIcon,
  Label as LabelIcon,
  ArrowBack as ArrowBackIcon,
  Description as DescriptionIcon,
  Close as CloseIcon,
  KeyboardArrowLeft,
  KeyboardArrowRight,
  InsertDriveFile as FileIcon,
  Image as ImageIcon,
  AudioFile as AudioIcon,
  BrokenImage as BrokenImageIcon,
  DirectionsCar,
  Assignment,
  Schedule,
  NoteAdd,
  Chat,
  TableChart,
  Article,
  Person,
  Phone as PhoneIcon,
} from '@mui/icons-material';
import { motion, AnimatePresence } from 'framer-motion';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { vscDarkPlus } from 'react-syntax-highlighter/dist/esm/styles/prism';
import MuiAlert from '@mui/material/Alert';
import { useAuth } from '../contexts/AuthContext';
import SendMessageForm from './SendMessageForm';
import { useNavigate } from 'react-router-dom';
import MobileStepper from '@mui/material/MobileStepper';

const WSS_BASE_URL = process.env.REACT_APP_WSS_BASE_URL || 'wss://admin.aitomotivelab.com';
const API_DOMAIN = process.env.REACT_APP_API_DOMAIN || 'https://admin.aitomotivelab.com';

const formatMessageTime = (date, previousDate) => {
  const messageDate = new Date(date);
  const prevDate = previousDate ? new Date(previousDate) : null;
  
  const timeString = messageDate.toLocaleTimeString('it-IT', {
    hour: '2-digit',
    minute: '2-digit',
  });

  // Check if we need to show the date (first message or different day)
  const showDate = !prevDate || 
    messageDate.getDate() !== prevDate.getDate() ||
    messageDate.getMonth() !== prevDate.getMonth() ||
    messageDate.getFullYear() !== prevDate.getFullYear();

  return {
    time: timeString,
    showDate: showDate,
    dateString: showDate ? messageDate.toLocaleDateString('it-IT', {
      day: 'numeric',
      month: 'long',
      year: 'numeric'
    }) : null
  };
};

const formatMessageContent = (message) => {
  if (!message) return '';
  // Add a newline at the end of each message to force line breaks
  return message
    .replace(/\r\n/g, '\n') // Normalize line endings
    .replace(/\n{3,}/g, '\n\n') // Replace 3+ newlines with 2
    .trim() // Remove leading/trailing whitespace
    + '\n'; // Add newline at the end to force line break
};

// Styled Components
const RootContainer = styled(Container)(({ theme, isSmallScreen }) => ({
  height: '100vh',
  overflow: 'hidden',
  padding: isSmallScreen ? 0 : theme.spacing(2),
  display: 'flex',
  flexDirection: 'column',
}));

const StyledPaper = styled(Paper)(({ theme }) => ({
  height: '100%',
  backgroundColor: theme.palette.background.paper,
  borderRadius: 0,
  padding: 0,
  margin: 0,
  boxShadow: 'none',
  display: 'flex',
  flexDirection: 'column',
  overflow: 'hidden',
  [theme.breakpoints.up('md')]: {
    borderRadius: theme.shape.borderRadius,
    boxShadow: theme.shadows[1],
  }
}));

const ChatContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
  overflow: 'hidden',
}));

const HeaderSection = styled(Box)(({ theme }) => ({
  position: 'sticky',
  top: 0,
  backgroundColor: theme.palette.background.paper,
  zIndex: 10,
  borderBottom: `1px solid ${theme.palette.divider}`,
  padding: theme.spacing(2),
  [theme.breakpoints.up('sm')]: {
    padding: theme.spacing(3),
  },
}));

const MessageList = styled(List)(({ theme }) => ({
  flexGrow: 1,
  overflowY: 'auto',
  overflowX: 'hidden',
  padding: theme.spacing(2),
  margin: 0,
  '&::-webkit-scrollbar': {
    width: '8px',
  },
  '&::-webkit-scrollbar-track': {
    background: theme.palette.background.default,
  },
  '&::-webkit-scrollbar-thumb': {
    background: theme.palette.primary.main,
    borderRadius: '4px',
  },
  '&::-webkit-scrollbar-thumb:hover': {
    background: theme.palette.primary.dark,
  },
}));

const FixedBottomBox = styled(Box)(({ theme }) => ({
  position: 'sticky',
  bottom: 0,
  left: 0,
  right: 0,
  backgroundColor: theme.palette.background.paper,
  borderTop: `1px solid ${theme.palette.divider}`,
  padding: theme.spacing(2),
  [theme.breakpoints.up('sm')]: {
    padding: theme.spacing(2, 3),
  },
  zIndex: 10,
}));

const AnimatedIconButton = styled(IconButton)(({ theme }) => ({
  transition: 'all 0.3s cubic-bezier(0.4, 0, 0.2, 1)',
  '&:hover': {
    transform: 'scale(1.2) rotate(5deg)',
    backgroundColor: theme.palette.primary.light,
  },
}));

const MessagePaper = styled(Paper)(({ theme, isBot, isWhatsApp }) => ({
  padding: theme.spacing(1.5),
  marginTop: theme.spacing(1),
  marginBottom: theme.spacing(1),
  backgroundColor: isBot
    ? isWhatsApp
      ? '#DCF8C6'
      : theme.palette.primary.light
    : isWhatsApp
    ? '#E2E2E2'
    : theme.palette.background.paper,
  color: isBot
    ? isWhatsApp
      ? '#000000'
      : theme.palette.primary.contrastText
    : theme.palette.text.primary,
  borderRadius: 12,
  boxShadow: '0px 4px 15px rgba(0, 0, 0, 0.1)',
  transition: 'all 0.3s ease',
  position: 'relative',
  '&:hover': {
    transform: 'translateY(-2px)',
    boxShadow: '0px 8px 20px rgba(0, 0, 0, 0.15)',
  },
  maxWidth: '95%',
  wordBreak: 'break-word',
  overflowWrap: 'break-word',
  whiteSpace: 'pre-wrap',
  '& p': {
    margin: 0,
    whiteSpace: 'pre-wrap',
    wordBreak: 'break-word',
    display: 'block',
    minHeight: '1.2em',
    color: isWhatsApp ? '#000000' : 'inherit',
  },
  '& > *:last-child': {
    marginBottom: 0,
  },
  [theme.breakpoints.up('sm')]: {
    padding: theme.spacing(4),
    maxWidth: '100%',
    borderRadius: 16,
    '&:hover': {
      transform: 'translateY(-5px)',
    },
  },
}));

const SummaryAccordion = styled(Accordion)(({ theme }) => ({
  margin: 0,
  '&:before': {
    display: 'none',
  },
  '&.Mui-expanded': {
    margin: 0,
  },
}));

const SummaryAccordionSummary = styled(AccordionSummary)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#2A2F3B' : '#E8F0FE',
  minHeight: 48,
  '& .MuiAccordionSummary-content': {
    margin: '8px 0',
  },
}));

const SummaryAccordionDetails = styled(AccordionDetails)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#F9FAFB',
  padding: theme.spacing(1, 2),
}));

const CarAttachmentsContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  overflowX: 'auto',
  gap: theme.spacing(2),
  padding: theme.spacing(1),
  marginTop: theme.spacing(1),
  '&::-webkit-scrollbar': {
    height: '6px',
  },
  '&::-webkit-scrollbar-track': {
    background: theme.palette.background.default,
  },
  '&::-webkit-scrollbar-thumb': {
    background: theme.palette.primary.main,
    borderRadius: '3px',
  },
  [theme.breakpoints.up('md')]: {
    display: 'grid',
    gridTemplateColumns: 'repeat(auto-fill, minmax(200px, 1fr))',
    overflowX: 'visible',
  },
}));

const CarStockCard = ({ car, isSmallScreen }) => (
  <motion.div
    whileHover={{ scale: 1.02 }}
    whileTap={{ scale: 0.98 }}
    transition={{ duration: 0.2 }}
  >
    <Card
      sx={{
        width: isSmallScreen ? '200px' : '100%',
        flexShrink: 0,
        m: 0,
        boxShadow: 3,
        borderRadius: 2,
        overflow: 'hidden',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <CardMedia
        component="img"
        height="120"
        image={car.urlmainimage || '/api/placeholder/345/140'}
        alt={`${car.brand} ${car.model}`}
        sx={{ objectFit: 'cover' }}
      />
      <CardContent sx={{ p: 1.5, '&:last-child': { pb: 1.5 } }}>
        <Typography variant="subtitle2" noWrap>
          {car.brand} {car.model}
        </Typography>
        <Typography variant="body2" color="text.secondary">
          €{car.saleprice?.toLocaleString()}
        </Typography>
        <Typography variant="caption" color="text.secondary" display="block">
          {car.registrationyear} • {car.km?.toLocaleString()} km
        </Typography>
      </CardContent>
    </Card>
  </motion.div>
);

const MobileCarouselContainer = styled(Box)(({ theme }) => ({
  position: 'relative',
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  marginTop: theme.spacing(2),
  marginBottom: theme.spacing(2),
  overflowX: 'hidden',
  '& .carousel-content': {
    width: '100%',
    position: 'relative',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
}));

const CarouselArrowButton = styled(IconButton)(({ theme }) => ({
  position: 'absolute',
  top: '50%',
  transform: 'translateY(-50%)',
  backgroundColor: theme.palette.background.paper,
  '&:hover': {
    backgroundColor: theme.palette.action.hover,
  },
  zIndex: 2,
  boxShadow: theme.shadows[2],
}));

const MobileCarCarousel = ({ cars }) => {
  const [activeStep, setActiveStep] = useState(0);
  const maxSteps = cars.length;

  const handleNext = () => {
    setActiveStep((prevStep) => prevStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevStep) => prevStep - 1);
  };

  return (
    <MobileCarouselContainer>
      <Box className="carousel-content" sx={{ width: '100%', position: 'relative' }}>
        <CarouselArrowButton
          size="small"
          onClick={handleBack}
          disabled={activeStep === 0}
          sx={{ left: 8 }}
        >
          <KeyboardArrowLeft />
        </CarouselArrowButton>

        <Box
          sx={{
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            padding: '0 40px',
          }}
        >
          <AnimatePresence mode="wait">
            <motion.div
              key={activeStep}
              initial={{ opacity: 0, x: 100 }}
              animate={{ opacity: 1, x: 0 }}
              exit={{ opacity: 0, x: -100 }}
              transition={{ duration: 0.3 }}
            >
              <CarStockCard car={cars[activeStep]} isSmallScreen={true} />
            </motion.div>
          </AnimatePresence>
        </Box>

        <CarouselArrowButton
          size="small"
          onClick={handleNext}
          disabled={activeStep === maxSteps - 1}
          sx={{ right: 8 }}
        >
          <KeyboardArrowRight />
        </CarouselArrowButton>
      </Box>

      <MobileStepper
        steps={maxSteps}
        position="static"
        activeStep={activeStep}
        sx={{
          width: '100%',
          justifyContent: 'center',
          background: 'transparent',
          mt: 1,
          '& .MuiMobileStepper-dot': {
            margin: '0 4px',
          },
        }}
      />
    </MobileCarouselContainer>
  );
};

const SummaryTable = ({ summary }) => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  
  const parseSummary = (text) => {
    const sections = {
      vehicles: '',
      mainRequest: '',
      timing: {
        status: 'REQUIRES CUSTOMER SERVICE ORGANIZATION',
        details: ''
      },
      additionalNotes: '',
      conversationSummary: ''
    };

    // For timing, get everything between "ORGANIZATION -" and "- Note Aggiuntive"
    const timingMatch = text.match(/ORGANIZATION -([^-]+)- Note Aggiuntive/);
    if (timingMatch && timingMatch[1]) {
      sections.timing.details = timingMatch[1].trim();
      sections.timing.status = ''; // Clear the status since we have details
    }

    // Parse other sections normally
    const parts = text.split(/[-\n]+/).map(part => part.trim()).filter(Boolean);
    parts.forEach(part => {
      if (part.startsWith('Veicoli:')) {
        sections.vehicles = part.replace('Veicoli:', '').trim();
      } else if (part.startsWith('Richiesta Principale:')) {
        sections.mainRequest = part.replace('Richiesta Principale:', '').trim();
      } else if (part.startsWith('Note Aggiuntive:')) {
        sections.additionalNotes = part.replace('Note Aggiuntive:', '').trim();
      } else if (part.startsWith('Riassunto conversazionale:')) {
        sections.conversationSummary = part.replace('Riassunto conversazionale:', '').trim();
      }
    });

    return sections;
  };

  const sections = parseSummary(summary);

  return (
    <Box sx={{ 
      width: '100%', 
      overflowX: 'auto',
      borderRadius: 2,
      bgcolor: 'background.paper',
      boxShadow: theme.shadows[3],
      p: isSmallScreen ? 1 : 2,
    }}>
      <Box sx={{
        display: 'grid',
        gap: 2,
        gridTemplateColumns: isSmallScreen ? '1fr' : 'repeat(2, 1fr)', // Two columns on desktop
      }}>
        {[
          { 
            icon: <DirectionsCar />, 
            label: 'Veicoli', 
            value: sections.vehicles,
            color: theme.palette.primary.main,
            bgColor: alpha(theme.palette.primary.main, 0.1)
          },
          { 
            icon: <Assignment />, 
            label: 'Richiesta Principale', 
            value: sections.mainRequest,
            color: theme.palette.secondary.main,
            bgColor: alpha(theme.palette.secondary.main, 0.1)
          },
          { 
            icon: <Schedule />, 
            label: 'Tempistiche',
            value: sections.timing.details || sections.timing.status,
            isStatus: !sections.timing.details,
            color: theme.palette.warning.main,
            bgColor: alpha(theme.palette.warning.main, 0.1)
          },
          { 
            icon: <NoteAdd />, 
            label: 'Note Aggiuntive', 
            value: sections.additionalNotes,
            color: theme.palette.info.main,
            bgColor: alpha(theme.palette.info.main, 0.1)
          },
          { 
            icon: <Chat />, 
            label: 'Riassunto Conversazione', 
            value: sections.conversationSummary,
            color: theme.palette.success.main,
            bgColor: alpha(theme.palette.success.main, 0.1),
            gridColumn: isSmallScreen ? 'auto' : '1 / -1' // Full width on desktop
          }
        ].map((row, index) => (
          <Paper
            key={index}
            elevation={2}
            sx={{
              display: 'flex',
              flexDirection: 'column',
              overflow: 'hidden',
              borderRadius: 2,
              transition: 'all 0.3s ease',
              '&:hover': {
                transform: 'translateY(-2px)',
                boxShadow: theme.shadows[8],
              },
            }}
          >
            {/* Header */}
            <Box
              sx={{
                p: 2,
                display: 'flex',
                alignItems: 'center',
                gap: 2,
                borderBottom: `1px solid ${theme.palette.divider}`,
                bgcolor: row.bgColor,
              }}
            >
              <Avatar
                sx={{
                  bgcolor: row.color,
                  color: '#fff',
                  width: 40,
                  height: 40,
                }}
              >
                {React.cloneElement(row.icon, { sx: { fontSize: '1.5rem' } })}
              </Avatar>
              <Typography
                variant="h6"
                sx={{
                  color: row.color,
                  fontWeight: 600,
                  fontSize: isSmallScreen ? '1rem' : '1.25rem',
                }}
              >
                {row.label}
              </Typography>
            </Box>

            {/* Content */}
            <Box
              sx={{
                p: 2,
                minHeight: row.isStatus ? 'auto' : '100px',
                display: 'flex',
                alignItems: row.isStatus ? 'center' : 'flex-start',
                bgcolor: theme.palette.background.paper,
              }}
            >
              {row.isStatus ? (
                <Chip
                  label={row.value}
                  color="primary"
                  sx={{
                    fontWeight: 500,
                    fontSize: '0.875rem',
                    py: 2,
                    px: 1,
                    height: 'auto',
                    '& .MuiChip-label': {
                      px: 2,
                    },
                  }}
                />
              ) : (
                <Typography
                  variant="body1"
                  sx={{
                    color: theme.palette.text.primary,
                    lineHeight: 1.6,
                    whiteSpace: 'pre-wrap',
                    wordBreak: 'break-word',
                  }}
                >
                  {row.value || 'Not specified'}
                </Typography>
              )}
            </Box>
          </Paper>
        ))}
      </Box>
    </Box>
  );
};

const SummarySection = ({ summary }) => {
  const [summaryViewMode, setSummaryViewMode] = useState('table');
  const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down('sm'));
  const theme = useTheme();

  return (
    <Box sx={{ position: 'relative' }}>
      <Box sx={{ 
        position: 'absolute', 
        top: isSmallScreen ? 4 : 8, 
        right: isSmallScreen ? 4 : 8, 
        zIndex: 1,
        display: 'flex',
        gap: 1,
        bgcolor: 'background.paper',
        borderRadius: 2,
        p: 1,
        boxShadow: theme.shadows[2],
      }}>
        <Tooltip title="Table View">
          <IconButton 
            size={isSmallScreen ? "small" : "medium"}
            onClick={() => setSummaryViewMode('table')}
            color={summaryViewMode === 'table' ? 'primary' : 'default'}
            sx={{
              '&:hover': {
                bgcolor: theme.palette.primary.light,
                color: theme.palette.primary.contrastText,
              },
            }}
          >
            <TableChart fontSize={isSmallScreen ? "small" : "medium"} />
          </IconButton>
        </Tooltip>
        <Tooltip title="Text View">
          <IconButton
            size={isSmallScreen ? "small" : "medium"}
            onClick={() => setSummaryViewMode('text')}
            color={summaryViewMode === 'text' ? 'primary' : 'default'}
            sx={{
              '&:hover': {
                bgcolor: theme.palette.primary.light,
                color: theme.palette.primary.contrastText,
              },
            }}
          >
            <Article fontSize={isSmallScreen ? "small" : "medium"} />
          </IconButton>
        </Tooltip>
      </Box>
      
      <Fade in={true}>
        <Box sx={{ mt: isSmallScreen ? 5 : 6 }}>
          {summaryViewMode === 'table' ? (
            <SummaryTable summary={summary} />
          ) : (
            <Paper
              elevation={3}
              sx={{
                whiteSpace: 'pre-wrap',
                p: isSmallScreen ? 2 : 3,
                borderRadius: 2,
                fontSize: isSmallScreen ? '0.875rem' : '1rem',
                lineHeight: 1.6,
                '& p': {
                  mb: 2,
                },
              }}
            >
              <Typography variant="body1">
                {summary}
              </Typography>
            </Paper>
          )}
        </Box>
      </Fade>
    </Box>
  );
};

const ClientInfoSection = styled(Box)(({ theme }) => ({
  padding: theme.spacing(2, 3),
  borderBottom: `1px solid ${theme.palette.divider}`,
  backgroundColor: theme.palette.background.paper,
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(1),
  [theme.breakpoints.down('sm')]: {
    padding: theme.spacing(2),
  },
}));

const ClientInformation = ({ clientName, clientEmail }) => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const isValidPhoneNumber = (phone) => {
    if (!phone) return false;
    // Check if it's not a temporary ID
    if (phone.includes('temporary') || phone.includes('_dcce')) return false;
    // Basic phone number validation (can be enhanced based on your needs)
    return /^\+?[\d\s-]{10,}$/.test(phone.replace(/\D/g, ''));
  };

  const formatPhoneNumber = (phone) => {
    if (!isValidPhoneNumber(phone)) return null;
    const cleaned = phone.replace(/\D/g, '');
    return cleaned.startsWith('39') ? cleaned : `39${cleaned}`;
  };

  const getWhatsAppLink = (phone) => {
    const formattedPhone = formatPhoneNumber(phone);
    return formattedPhone ? `https://wa.me/${formattedPhone}` : null;
  };

  return (
    <ClientInfoSection>
      <Box sx={{ 
        display: 'flex', 
        justifyContent: 'space-between',
        alignItems: 'center',
        mb: 2
      }}>
        <Typography 
          variant="h6" 
          sx={{ 
            display: 'flex', 
            alignItems: 'center',
            gap: 1,
            color: theme.palette.primary.main,
            fontWeight: 600
          }}
        >
          <Person sx={{ fontSize: '1.5rem' }} />
          Informazioni Cliente
        </Typography>
      </Box>

      <Paper
        elevation={2}
        sx={{
          p: 2,
          borderRadius: 2,
          bgcolor: alpha(theme.palette.primary.main, 0.04),
          transition: 'all 0.3s ease',
          '&:hover': {
            transform: 'translateY(-2px)',
            boxShadow: theme.shadows[4],
          },
        }}
      >
        <Box sx={{
          display: 'flex',
          flexDirection: isSmallScreen ? 'column' : 'row',
          gap: isSmallScreen ? 2 : 3,
          alignItems: isSmallScreen ? 'flex-start' : 'center',
          justifyContent: 'space-between'
        }}>
          {/* Client Name Section */}
          <Box sx={{
            display: 'flex',
            alignItems: 'center',
            gap: 2,
            flex: 1
          }}>
            <Avatar 
              sx={{ 
                width: 48, 
                height: 48,
                bgcolor: theme.palette.primary.main,
                boxShadow: theme.shadows[2]
              }}
            >
              {clientName ? clientName.charAt(0).toUpperCase() : <PersonIcon />}
            </Avatar>
            <Box>
              <Typography 
                variant="subtitle1" 
                sx={{ 
                  fontWeight: 600,
                  color: theme.palette.text.primary
                }}
              >
                {clientName || 'Cliente Anonimo'}
              </Typography>
              <Typography 
                variant="caption" 
                sx={{ 
                  color: theme.palette.text.secondary,
                  display: 'block'
                }}
              >
                {isValidPhoneNumber(clientEmail) ? 'Contatto telefonico' : 'Cliente ID'}: {clientEmail || 'N/A'}
              </Typography>
            </Box>
          </Box>

          {/* Contact Actions - Only show if valid phone number */}
          {clientEmail && isValidPhoneNumber(clientEmail) && (
            <Box sx={{
              display: 'flex',
              alignItems: 'center',
              gap: 1,
              [theme.breakpoints.down('sm')]: {
                width: '100%',
                pl: 7,
              }
            }}>
              <Chip
                icon={<PhoneIcon sx={{ fontSize: 16 }} />}
                label={clientEmail}
                variant="outlined"
                clickable
                component="a"
                href={`tel:${clientEmail}`}
                sx={{
                  borderRadius: '16px',
                  borderColor: theme.palette.primary.main,
                  color: theme.palette.primary.main,
                  '& .MuiChip-icon': {
                    color: theme.palette.primary.main,
                  },
                  '& .MuiChip-label': {
                    px: 1,
                  },
                  transition: 'all 0.2s ease',
                  '&:hover': {
                    bgcolor: alpha(theme.palette.primary.main, 0.1),
                    borderColor: theme.palette.primary.dark,
                  },
                  [theme.breakpoints.down('sm')]: {
                    flexGrow: 1,
                    maxWidth: 'calc(100% - 48px)', // Account for WhatsApp button
                  }
                }}
              />
              <Tooltip title="Send WhatsApp message">
                <IconButton
                  component="a"
                  href={getWhatsAppLink(clientEmail)}
                  target="_blank"
                  rel="noopener noreferrer"
                  size={isSmallScreen ? "small" : "medium"}
                  sx={{
                    color: '#25D366',
                    flexShrink: 0,
                    '&:hover': {
                      bgcolor: alpha('#25D366', 0.1),
                    },
                  }}
                >
                  <svg 
                    xmlns="http://www.w3.org/2000/svg" 
                    width={isSmallScreen ? "20" : "24"} 
                    height={isSmallScreen ? "20" : "24"} 
                    viewBox="0 0 24 24"
                    style={{ fill: 'currentColor' }}
                  >
                    <path d="M12.031 6.172c-3.181 0-5.767 2.586-5.768 5.766-.001 1.298.38 2.27 1.019 3.287l-.582 2.128 2.182-.573c.978.58 1.911.928 3.145.929 3.178 0 5.767-2.587 5.768-5.766.001-3.187-2.575-5.77-5.764-5.771zm3.392 8.244c-.144.405-.837.774-1.17.824-.299.045-.677.063-1.092-.069-.252-.08-.575-.187-.988-.365-1.739-.751-2.874-2.502-2.961-2.617-.087-.116-.708-.94-.708-1.793s.448-1.273.607-1.446c.159-.173.346-.217.462-.217l.332.006c.106.005.249-.04.39.298.144.347.491 1.2.534 1.287.043.087.072.188.014.304-.058.116-.087.188-.173.289l-.26.304c-.087.086-.177.18-.076.354.101.174.449.741.964 1.201.662.591 1.221.774 1.394.86s.274.072.376-.043c.101-.116.433-.506.549-.68.116-.173.231-.145.39-.087s1.011.477 1.184.564.289.13.332.202c.045.072.045.419-.1.824zm-3.423-14.416c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm.029 18.88c-1.161 0-2.305-.292-3.318-.844l-3.677.964.984-3.595c-.607-1.052-.927-2.246-.926-3.468.001-3.825 3.113-6.937 6.937-6.937 1.856.001 3.598.723 4.907 2.034 1.31 1.311 2.031 3.054 2.03 4.908-.001 3.825-3.113 6.938-6.937 6.938z"/>
                  </svg>
                </IconButton>
              </Tooltip>
            </Box>
          )}
        </Box>
      </Paper>
    </ClientInfoSection>
  );
};

const ChatHistoryDetail = ({ 
  chatId, 
  humanControl, 
  onClose,
  clientName,
  clientEmail,
  wsConnection = null,
  whatsapp,
  isConnected,
  onPredictMessage,
  setHumanControl,
  isCurrentUserController
}) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const isMediumScreen = useMediaQuery(theme.breakpoints.between('sm', 'md'));
  const [chatHistory, setChatHistory] = useState([]);
  const [chatSummary, setChatSummary] = useState('');
  const [labelSegmentation, setLabelSegmentation] = useState('');
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [editing, setEditing] = useState(false);
  const [editingMessage, setEditingMessage] = useState(null);
  const [snackbar, setSnackbar] = useState({ open: false, message: '', type: 'success' });
  const [feedbackDialog, setFeedbackDialog] = useState({ open: false, messageId: null });
  const [feedback, setFeedback] = useState({ category: '', comment: '' });
  const lastMessageRef = useRef(null);
  const [unsentMessage, setUnsentMessage] = useState(null);
  const [summaryExpanded, setSummaryExpanded] = useState(false);
  const wsRef = useRef(null);
  const [authError, setAuthError] = useState(false);
  const isManuallyClosed = useRef(false);
  const reconnectionAttempts = useRef(0);
  const tempIdCounter = useRef(0);
  const [summaryDialogOpen, setSummaryDialogOpen] = useState(false);
  const [summaryViewMode, setSummaryViewMode] = useState('table');
  const [isInitialized, setIsInitialized] = useState(false);
  const [wsStatus, setWsStatus] = useState('connecting');
  const messageQueue = useRef([]);
  const [isPredicting, setIsPredicting] = useState(false);

  const { userRole, appAccess, token = localStorage.getItem('token') } = useAuth();
  const permissions = appAccess['ai_app'] || {};
  const canEdit = permissions.can_edit || userRole === 'superadmin';

  const FileDisplay = styled(Box)(({ theme }) => ({
    marginTop: theme.spacing(2),
    padding: theme.spacing(2),
    borderRadius: theme.shape.borderRadius,
    backgroundColor: theme.palette.background.default,
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(2),
  }));

  const DateDivider = styled(Box)(({ theme }) => ({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    margin: theme.spacing(3, 0),
    '&::before, &::after': {
      content: '""',
      flex: 1,
      borderBottom: `1px solid ${theme.palette.divider}`,
    },
  }));

  const DateChip = styled(Chip)(({ theme }) => ({
    margin: theme.spacing(0, 2),
    backgroundColor: theme.palette.grey[100],
    color: theme.palette.text.secondary,
    fontSize: '0.75rem',
    height: 24,
  }));

  // Separate effect for handling WebSocket messages
  useEffect(() => {
    if (!wsConnection || !chatId) return;

    const handleMessage = (event) => {
      try {
        const data = JSON.parse(event.data);
        handleWebSocketMessage(data);
      } catch (error) {
        console.error('Failed to parse WebSocket message:', error);
      }
    };

    wsConnection.addEventListener('message', handleMessage);
    
    // Send initial request only once when connection is ready
    if (!isInitialized && wsConnection.readyState === WebSocket.OPEN) {
      const requestData = {
        action: 'full_chat',
        pk: chatId,
        context: {
          user_role: userRole,
          permissions: permissions
        }
      };
      wsConnection.send(JSON.stringify(requestData));
      setIsInitialized(true);
    }

    return () => {
      wsConnection.removeEventListener('message', handleMessage);
    };
  }, [wsConnection, chatId, isInitialized, userRole, permissions]);

  // Effect for handling local WebSocket if no shared connection
  useEffect(() => {
    if (wsConnection) {
      setWsStatus('connected');
      return;
    }

    let localWs = null;

    const initializeWebSocket = () => {
      try {
        localWs = new WebSocket(`${WSS_BASE_URL}/ws/admin_chat/?token=${token}`);
        wsRef.current = localWs;

        localWs.onopen = () => {
          console.log('Local WebSocket Connected');
          setWsStatus('connected');
          setAuthError(false);
          
          // Process any queued messages
          if (messageQueue.current.length > 0) {
            messageQueue.current.forEach(message => {
              localWs.send(JSON.stringify(message));
            });
            messageQueue.current = [];
          }
        };

        localWs.onerror = (error) => {
          console.error('WebSocket Error:', error);
          setWsStatus('error');
          setError('Connection error. Please try again.');
        };

        localWs.onclose = () => {
          setWsStatus('closed');
          if (!isManuallyClosed.current) {
            setError('Connection closed. Please refresh the page.');
          }
        };

        localWs.onmessage = (event) => {
          try {
            const data = JSON.parse(event.data);
            handleWebSocketMessage(data);
          } catch (error) {
            console.error('Failed to parse WebSocket message:', error);
          }
        };
      } catch (error) {
        console.error('Failed to create WebSocket:', error);
        setWsStatus('error');
        setError('Failed to connect to chat server');
      }
    };

    initializeWebSocket();

    return () => {
      isManuallyClosed.current = true;
      setWsStatus('closed');
      if (localWs) {
        localWs.close();
      }
    };
  }, [token]);

  // Reset initialization when chatId changes
  useEffect(() => {
    setIsInitialized(false);
    setLoading(true);
    setChatHistory([]);
    setChatSummary('');
    setLabelSegmentation('');
    setError(null);
  }, [chatId]);

  const handleWebSocketMessage = (data) => {
    console.log('Received WebSocket message:', data);
    
    // Handle specific chat-in-use error
    if (data.error === 'Chat is currently in use by another user') {
      console.log('Chat is in use by another user');
      if (setHumanControl) {
        setHumanControl(false);
      }
      setError(data.error);
      setLoading(false);
      return;
    }

    switch (data.action || data.type) {
      case 'full_chat':
        if (data.error) {
          console.error('Error fetching chat:', data.error);
          setError(data.error);
          setChatHistory([]);
          setLoading(false);
          return;
        }
        
        if (!data.data) {
          console.error('Invalid data structure received');
          setError('Invalid data received from server');
          setChatHistory([]);
          setLoading(false);
          return;
        }

        try {
          const chatData = Array.isArray(data.data) ? data.data : [];
          console.log('Processing chat data:', chatData);
          
          // Get whatsapp status from chat info and apply to all messages
          const chatInfo = chatData[0]?.chat || {};
          const isWhatsApp = chatInfo.whatsapp || false;
          
          // Apply whatsapp property to all messages
          const processedChatData = chatData.map(message => ({
            ...message,
            whatsapp: isWhatsApp
          }));
          
          setChatHistory(processedChatData);
          setChatSummary(chatInfo.summary || '');
          setLabelSegmentation(data.label_segmentation || '');
          setLoading(false);
          
        } catch (err) {
          console.error('Error processing chat data:', err);
          setError('Error processing chat data');
          setChatHistory([]);
          setLoading(false);
        }
        break;

      case 'new_message':
      case 'update_chat':
        console.log("update_chat/new_message received:", data);
        if (data.data) {
          const incomingMessage = {
            id: data.data.id || data.data.messageId,
            chat_id: data.data.chat_id,
            // Format messages to preserve intentional line breaks but remove extra spaces
            sender_message: data.data.sender_message?.replace(/\r\n/g, '\n').replace(/\s+/g, ' ').trim(),
            bot_message: data.data.bot_message?.replace(/\r\n/g, '\n').replace(/\s+/g, ' ').trim(),
            created_at: data.data.created_at || new Date().toISOString(),
            sent: data.data.sent !== false,
            sources: data.data.sources || [],
            car_stocks: data.data.car_stocks || data.data.sources || [],
            tool: data.data.tool,
            whatsapp: data.data.whatsapp
          };

          setChatHistory((prevHistory) => {
            if (!prevHistory) return [incomingMessage];
            
            const index = prevHistory.findIndex(
              (msg) => msg.id === incomingMessage.id || msg.id === data.data.messageId
            );

            if (index !== -1) {
              const updatedHistory = [...prevHistory];
              updatedHistory[index] = {
                ...updatedHistory[index],
                ...incomingMessage
              };
              return updatedHistory;
            } else {
              return [...prevHistory, incomingMessage];
            }
          });
        }
        break;

      case 'message_sent':
        const { chatHistoryId, temp_id } = data;
        setChatHistory((prevHistory) => {
          const index = prevHistory.findIndex((msg) => msg.id === temp_id);
          if (index !== -1) {
            const updatedMessage = {
              ...prevHistory[index],
              id: chatHistoryId,
              isTemp: false,
            };
            const newHistory = [...prevHistory];
            newHistory[index] = updatedMessage;
            return newHistory;
          }
          return prevHistory;
        });
        setUnsentMessage(null);
        break;
      case 'message_status_updated':
        const { chatHistoryId: updatedId, sent } = data;
        setChatHistory((prevHistory) => {
          const index = prevHistory.findIndex((msg) => msg.id === updatedId);
          if (index !== -1) {
            const updatedMessage = {
              ...prevHistory[index],
              sent: sent,
            };
            const newHistory = [...prevHistory];
            newHistory[index] = updatedMessage;
            return newHistory;
          }
          return prevHistory;
        });
        break;
      case 'predict_message':
        if (data.data) {
          const predictedMessage = data.data;
          console.log('Processing predicted message:', predictedMessage);
          
          // Create a standardized message object, preserving sender_message if it exists
          const messageObject = {
            id: predictedMessage.id,
            chat_id: predictedMessage.chat_id,
            sender_message: predictedMessage.sender_message, // Preserve sender message
            bot_message: predictedMessage.bot_message,
            sent: predictedMessage.sent,
            status: predictedMessage.status,
            sources: predictedMessage.sources || [],
            tool: predictedMessage.tool,
            car_stocks: predictedMessage.sources || [], // Use sources as car_stocks
            created_at: new Date().toISOString(), // Add timestamp if not present
          };

          // Add the message to chat history, preserving existing sender message if updating
          setChatHistory(prevHistory => {
            const index = prevHistory.findIndex(msg => msg.id === messageObject.id);
            if (index !== -1) {
              const updatedHistory = [...prevHistory];
              // Merge with existing message to preserve sender_message
              updatedHistory[index] = {
                ...updatedHistory[index],
                ...messageObject,
                sender_message: updatedHistory[index].sender_message || messageObject.sender_message,
              };
              return updatedHistory;
            }
            return [...prevHistory, messageObject];
          });

          // If message is not sent, also set it as unsent message
          if (predictedMessage.sent === false) {
            console.log('Setting unsent message:', messageObject);
            setUnsentMessage(messageObject);
            setPredicting(false);
          }
        }
        break;
      default:
        console.log('Unhandled WebSocket message:', data);
    }
  };

  useEffect(() => {
    if (Array.isArray(chatHistory) && chatHistory.length > 0 && lastMessageRef.current) {
      lastMessageRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [chatHistory]);

  useEffect(() => {
    const lastMessage = chatHistory[chatHistory.length - 1];
    if (lastMessage && lastMessage.sent === false && lastMessage.sender_message) {
      setUnsentMessage(lastMessage);
    } else {
      setUnsentMessage(null);
    }
  }, [chatHistory]);

  const sendWebSocketMessage = (message) => {
    const ws = wsConnection || wsRef.current;
    
    if (ws?.readyState === WebSocket.OPEN) {
      ws.send(JSON.stringify(message));
      return true;
    } else {
      console.log('WebSocket not ready, queueing message:', message);
      messageQueue.current.push(message);
      return false;
    }
  };

  useEffect(() => {
    const ws = wsConnection || wsRef.current;
    
    if (ws?.readyState === WebSocket.OPEN && messageQueue.current.length > 0) {
      console.log('Processing queued messages');
      messageQueue.current.forEach(message => {
        ws.send(JSON.stringify(message));
      });
      messageQueue.current = [];
    }
  }, [wsStatus, wsConnection]);

  const handleSendMessage = (message) => {
    // Check if there is a last unsent message
    if (unsentMessage) {
      // Update the last unsent message to sent
      sendWebSocketMessage({
        action: 'update_message_status',
        chatHistoryId: unsentMessage.id,
        sent: true,
      });
    }

    const tempId = `temp-${tempIdCounter.current++}`;
    const tempMessage = {
      id: tempId,
      bot_message: message.text,
      created_at: new Date().toISOString(),
      sent: true,
      isTemp: true,
      car_stocks: message.cars || message.sources, // Accept either cars or sources
      sources: message.sources || message.cars, // Store both ways for compatibility
      tool: message.tool
    };
    setChatHistory((prevHistory) => [...prevHistory, tempMessage]);

    sendWebSocketMessage({
      action: 'send_message',
      chatId: chatId,
      messageId: tempId,
      message: message.text,
      car_stocks: message.cars || message.sources,
      sources: message.sources || message.cars,
      sent: true,
      include_registration_form: message.include_registration_form,
      tool: message.tool
    });
  };

  const handleFeedback = (messageId, type) => {
    if (type === 'comment') {
      setFeedbackDialog({ open: true, messageId });
    } else {
      sendWebSocketMessage({
        action: 'send_feedback',
        messageId: messageId,
        feedbackType: type,
      });
    }
  };

  const handleFeedbackSubmit = () => {
    sendWebSocketMessage({
      action: 'send_feedback',
      messageId: feedbackDialog.messageId,
      feedback: feedback,
    });
    setFeedbackDialog({ open: false, messageId: null });
    setFeedback({ category: '', comment: '' });
  };

  const handleEdit = (message) => {
    setEditingMessage(message);
    setEditing(true);
  };

  const handleSave = () => {
    sendWebSocketMessage({
      action: 'edit_message',
      message: editingMessage,
    });
    setEditing(false);
    setEditingMessage(null);
  };

  const handleChange = (e) => {
    setEditingMessage({ ...editingMessage, [e.target.name]: e.target.value });
  };

  const MarkdownContent = ({ content }) => (
    <ReactMarkdown
      components={{
        h1: ({ node, ...props }) => <Typography variant="h4" gutterBottom {...props} />,
        h2: ({ node, ...props }) => <Typography variant="h5" gutterBottom {...props} />,
        h3: ({ node, ...props }) => <Typography variant="h6" gutterBottom {...props} />,
        p: ({ node, ...props }) => (
          <Typography 
            variant="body1" 
            sx={{ 
              whiteSpace: 'pre-wrap',
              wordBreak: 'break-word',
              mb: 1,
              '&:last-child': {
                mb: 0
              }
            }} 
            {...props} 
          />
        ),
        a: ({ node, ...props }) => <a style={{ color: theme.palette.primary.main }} {...props} />,
        code: ({ node, inline, className, children, ...props }) => {
          const match = /language-(\w+)/.exec(className || '');
          return !inline && match ? (
            <SyntaxHighlighter
              style={vscDarkPlus}
              language={match[1]}
              PreTag="div"
              {...props}
            >
              {String(children).replace(/\n$/, '')}
            </SyntaxHighlighter>
          ) : (
            <code className={className} {...props}>
              {children}
            </code>
          );
        },
      }}
    >
      {content}
    </ReactMarkdown>
  );

  const handleSummaryClick = () => {
    setSummaryDialogOpen(true);
  };

  const handleCloseSummaryDialog = () => {
    setSummaryDialogOpen(false);
  };

  const ChatSummaryAccordion = () => {
    if (isSmallScreen) {
      return (
        <Tooltip title="Informazioni Chat">
          <IconButton onClick={handleSummaryClick} size="small">
            <DescriptionIcon />
          </IconButton>
        </Tooltip>
      );
    }

    return (
      <SummaryAccordion
        expanded={summaryExpanded}
        onChange={() => setSummaryExpanded(!summaryExpanded)}
        sx={{
          mb: 0,
          '& .MuiAccordionSummary-root': {
            minHeight: 48,
          },
          '& .MuiAccordionSummary-content': {
            margin: '8px 0',
          },
        }}
      >
        <SummaryAccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="chat-summary-content"
          id="chat-summary-header"
        >
          <Typography variant="subtitle1">Informazioni Chat</Typography>
        </SummaryAccordionSummary>
        <SummaryAccordionDetails>
          <SummarySection summary={chatSummary} />
        </SummaryAccordionDetails>
      </SummaryAccordion>
    );
  };

  const getFileUrl = (fileObject) => {
    if (!fileObject) return null;

    // If fileObject is a string, return it directly
    if (typeof fileObject === 'string') return fileObject;

    // If fileObject is an object with a file_url property, return that
    if (typeof fileObject === 'object' && fileObject.file_url) {
      return fileObject.file_url;
    }

    return null;
  };

  const getAbsoluteUrl = (relativeUrl, token, chatId) => {
    if (!relativeUrl) return null;

    const urlString = String(relativeUrl);

    // If URL is already absolute, return it without adding parameters
    if (urlString.startsWith('http://') || urlString.startsWith('https://')) {
      return urlString;
    }

    // Build the base URL
    const baseUrl = `${API_DOMAIN}${urlString.startsWith('/') ? '' : '/'}${urlString}`;

    // Add token and chat_id as query parameters if they exist
    const params = new URLSearchParams();
    if (token) params.append('token', token);
    if (chatId) params.append('chat_id', chatId);

    const queryString = params.toString();
    console.log(queryString);
    return queryString ? `${baseUrl}?${queryString}` : baseUrl;
  };

  const getFileDetails = (fileUrl) => {
    if (!fileUrl) return { fileName: 'Unknown File', fileExtension: '' };

    const urlString = String(fileUrl);
    const urlWithoutParams = urlString.split('?')[0];
    const urlParts = urlWithoutParams.split('/');
    const fileName = urlParts[urlParts.length - 1];
    const fileExtension = fileName.split('.').pop()?.toLowerCase() || '';

    return { fileName, fileExtension };
  };

  const renderFile = (fileObject, isBot, currentToken, currentChatId) => {
    const fileUrl = getFileUrl(fileObject);
    if (!fileUrl) {
      console.log('No file URL provided:', fileObject);
      return null;
    }

    // Get file details from the original URL (without parameters)
    const { fileName, fileExtension } = getFileDetails(fileUrl);

    // Generate the absolute URL with parameters
    const absoluteUrl = getAbsoluteUrl(fileUrl, currentToken, currentChatId);
    if (!absoluteUrl) {
      console.error('Unable to generate absolute URL for:', fileUrl);
      return null;
    }

    // Log the URL being used
    console.log('Attempting to load file from:', absoluteUrl);

    // Expanded file type checks
    const imageExtensions = ['jpg', 'jpeg', 'png', 'gif', 'webp', 'bmp'];
    const isImage = imageExtensions.includes(fileExtension.toLowerCase());
    const isAudio = ['mp3', 'wav', 'ogg', 'm4a', 'aac'].includes(fileExtension.toLowerCase());

    // Create URL object to check validity
    let isValidUrl = false;
    try {
      new URL(absoluteUrl);
      isValidUrl = true;
    } catch (e) {
      console.error('Invalid URL:', absoluteUrl, e);
    }

    return (
      <FileDisplay>
        {isImage ? (
          <Box
            sx={{
              width: '100%',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              gap: 1,
            }}
          >
            <Box
              sx={{
                width: '100%',
                maxWidth: '500px',
                minHeight: '200px',
                position: 'relative',
                bgcolor: 'background.paper',
                borderRadius: 1,
                overflow: 'hidden',
                boxShadow: 1,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              {isValidUrl && (
                <>
                  <img
                    src={absoluteUrl}
                    alt={fileName}
                    style={{
                      display: 'block',
                      width: '100%',
                      height: 'auto',
                      maxHeight: '400px',
                      objectFit: 'contain',
                    }}
                    onError={(e) => {
                      console.error('Image load error for URL:', absoluteUrl);
                      console.error('File object:', fileObject);
                      console.error('Error event:', e);

                      e.target.onerror = null; // Prevent infinite loop
                      e.target.src = '/api/placeholder/400/300';
                      e.target.style.opacity = '0.5'; // Show it's a fallback
                    }}
                    onLoad={(e) => {
                      console.log('Image loaded successfully:', absoluteUrl);
                      e.target.style.opacity = '1';
                    }}
                  />
                  <Box
                    sx={{
                      position: 'absolute',
                      top: 0,
                      left: 0,
                      right: 0,
                      bottom: 0,
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      backgroundColor: 'rgba(255, 255, 255, 0.8)',
                      opacity: 0,
                      transition: 'opacity 0.3s',
                      '&.loading': {
                        opacity: 1,
                      },
                    }}
                    className="image-loading"
                  >
                    <CircularProgress size={40} />
                  </Box>
                </>
              )}
            </Box>

            <Box
              sx={{
                width: '100%',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                px: 1,
              }}
            >
              <Typography variant="caption" color="text.secondary">
                {fileName} {!isValidUrl && '(Invalid URL)'}
              </Typography>
              {isValidUrl && (
                <Tooltip title="Download image">
                  <IconButton
                    component="a"
                    href={absoluteUrl}
                    download={fileName}
                    target="_blank"
                    rel="noopener noreferrer"
                    size="small"
                  >
                    <ImageIcon fontSize="small" />
                  </IconButton>
                </Tooltip>
              )}
            </Box>
          </Box>
        ) : isAudio ? (
          <Box
            sx={{
              width: '100%',
              display: 'flex',
              flexDirection: 'column',
              gap: 1,
            }}
          >
            <Box
              sx={{
                width: '100%',
                maxWidth: '500px',
                bgcolor: 'background.paper',
                borderRadius: 1,
                p: 2,
                boxShadow: 1,
              }}
            >
              <audio
                controls
                style={{ width: '100%' }}
                src={absoluteUrl}
                onError={(e) => {
                  console.error('Audio load error:', e);
                  console.error('Audio URL:', absoluteUrl);
                }}
              >
                Your browser does not support the audio element.
              </audio>
            </Box>

            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                width: '100%',
                maxWidth: '500px',
                px: 1,
              }}
            >
              <Typography variant="caption" color="text.secondary">
                {fileName}
              </Typography>
              {isValidUrl && (
                <Tooltip title="Download audio">
                  <IconButton
                    component="a"
                    href={absoluteUrl}
                    download={fileName}
                    target="_blank"
                    rel="noopener noreferrer"
                    size="small"
                  >
                    <AudioIcon fontSize="small" />
                  </IconButton>
                </Tooltip>
              )}
            </Box>
          </Box>
        ) : (
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              width: '100%',
              bgcolor: 'background.paper',
              borderRadius: 1,
              p: 2,
              boxShadow: 1,
            }}
          >
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
              <FileIcon color="primary" />
              <Typography variant="body2">{fileName}</Typography>
            </Box>
            {isValidUrl && (
              <Tooltip title="Download file">
                <IconButton
                  component="a"
                  href={absoluteUrl}
                  download={fileName}
                  target="_blank"
                  rel="noopener noreferrer"
                  size="small"
                >
                  <FileIcon fontSize="small" />
                </IconButton>
              </Tooltip>
            )}
          </Box>
        )}
      </FileDisplay>
    );
  };

  const LabelSegmentationAccordion = () => (
    <SummaryAccordion>
      <SummaryAccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="label-segmentation-content"
        id="label-segmentation-header"
      >
        <Typography variant="h6">
          <LabelIcon sx={{ mr: 1, verticalAlign: 'middle' }} />
          Label Segmentation
        </Typography>
      </SummaryAccordionSummary>
      <SummaryAccordionDetails>
        <Typography>{labelSegmentation}</Typography>
      </SummaryAccordionDetails>
    </SummaryAccordion>
  );

  const handleCloseClick = () => {
    if (onClose) {
      onClose();
    }
  };

  const handlePredictMessage = useCallback((message = '') => {
    const ws = wsConnection || wsRef.current;
    
    if (ws?.readyState === WebSocket.OPEN) {
      console.log('Sending predict message request for chat:', chatId);
      const payload = {
        action: 'predict_message',
        chat_id: chatId
      };
      
      // Only add suggestion_ai if message is not empty
      if (message.trim()) {
        payload.suggestion_ai = message;
      }
      
      ws.send(JSON.stringify(payload));
    } else {
      setError('Connection to server lost. Please refresh the page.');
    }
  }, [chatId, wsConnection]);

  if (loading)
    return (
      <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
        <CircularProgress size={60} thickness={4} />
      </Box>
    );

  // Check for the "in use" error first
  if (error === 'Chat is currently in use by another user') {
    return (
      <Box 
        display="flex" 
        flexDirection="column" 
        alignItems="center" 
        justifyContent="center" 
        height="100vh"
        gap={2}
        p={3}
      >
        <Typography 
          variant="h6" 
          align="center"
          color="warning.main"
          sx={{
            display: 'flex',
            alignItems: 'center',
            gap: 1,
            mb: 1
          }}
        >
          <PersonIcon />
          This chat is currently being managed by another user
        </Typography>
        <Typography 
          variant="body1" 
          color="text.secondary"
          align="center"
        >
          Please select another chat to manage
        </Typography>
        <Button
          variant="contained"
          color="primary"
          onClick={handleCloseClick}
          startIcon={<ArrowBackIcon />}
          sx={{ mt: 2 }}
        >
          Back to Chat List
        </Button>
      </Box>
    );
  }

  // Then check for other errors
  if (error)
    return (
      <Box 
        display="flex" 
        flexDirection="column" 
        alignItems="center" 
        justifyContent="center" 
        height="100vh"
        gap={2}
        p={3}
      >
        <Typography 
          color="error" 
          variant="h6" 
          align="center"
          sx={{
            display: 'flex',
            alignItems: 'center',
            gap: 1
          }}
        >
          <ErrorIcon color="error" />
          {error}
        </Typography>
        <Button
          variant="contained"
          color="primary"
          onClick={handleCloseClick}
          startIcon={<ArrowBackIcon />}
        >
          Back to Chat List
        </Button>
      </Box>
    );

  if (chatHistory.length === 0) {
    return (
      <Typography variant="h5" align="center">
        No chat history found
      </Typography>
    );
  }

  return (
    <Container maxWidth={isSmallScreen ? 'sm' : 'lg'}>
      <StyledPaper elevation={3}>
        {/* Header Section */}
        <Box
          sx={{
            position: 'sticky',
            top: 0,
            zIndex: 10,
            backgroundColor: theme.palette.background.paper,
          }}
        >
          <Box sx={{ 
            p: 2, 
            borderBottom: `1px solid ${theme.palette.divider}`,
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center'
          }}>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <IconButton onClick={handleCloseClick} sx={{ mr: 2 }} aria-label="close">
                <CloseIcon />
              </IconButton>
              <Typography variant="h6">Chat Details</Typography>
            </Box>
            {/* Add summary icon for mobile */}
            {isSmallScreen && chatSummary && (
              <Tooltip title="View Summary">
                <IconButton onClick={() => setSummaryDialogOpen(true)}>
                  <DescriptionIcon />
                </IconButton>
              </Tooltip>
            )}
          </Box>

          {/* Client Information Section */}
          <ClientInformation 
            clientName={clientName}
            clientEmail={clientEmail}
          />

          {/* Chat Summary Section - only show on desktop */}
          {!isSmallScreen && (
            <>
              <ChatSummaryAccordion />
              {labelSegmentation && <LabelSegmentationAccordion />}
            </>
          )}
        </Box>

        {/* Scrollable Message List */}
        <MessageList>
          <AnimatePresence>
            {chatHistory
              .sort((a, b) => new Date(a.created_at) - new Date(b.created_at))
              .map((message, index) => {
                const previousMessage = index > 0 ? chatHistory[index - 1] : null;
                const { time, showDate, dateString } = formatMessageTime(
                  message.created_at,
                  previousMessage?.created_at
                );

                return (
                  <React.Fragment key={message.id}>
                    {showDate && (
                      <DateDivider>
                        <DateChip label={dateString} />
                      </DateDivider>
                    )}
                    <motion.div
                      initial={{ opacity: 0, y: 20 }}
                      animate={{ opacity: 1, y: 0 }}
                      exit={{ opacity: 0, y: -20 }}
                      transition={{ duration: 0.3 }}
                      ref={index === chatHistory.length - 1 ? lastMessageRef : null}
                    >
                      <ListItem
                        alignItems="flex-start"
                        sx={{
                          flexDirection: 'column',
                          padding: theme.spacing(1),
                          width: '100%',
                          [theme.breakpoints.down('sm')]: {
                            padding: theme.spacing(0.5),
                          },
                        }}
                      >
                        <Box
                          sx={{
                            width: '100%',
                            display: 'flex',
                            flexDirection: 'column',
                            gap: 1, // Add gap between messages
                          }}
                        >
                          {/* Sender Messages */}
                          {message.sender_message && (
                            <Box 
                              sx={{ 
                                position: 'relative', 
                                width: 'fit-content',
                                maxWidth: '100%',
                                alignSelf: 'flex-start',
                                ml: 1,
                              }}
                            >
                              <MessagePaper 
                                elevation={1} 
                                isBot={false} 
                                isWhatsApp={whatsapp || message.whatsapp}
                              >
                                <MarkdownContent content={formatMessageContent(message.sender_message)} />
                                <Typography
                                  variant="caption"
                                  sx={{
                                    position: 'absolute',
                                    right: 8,
                                    bottom: 4,
                                    color: 'text.secondary',
                                    fontSize: '0.7rem',
                                  }}
                                >
                                  {time}
                                </Typography>
                              </MessagePaper>
                            </Box>
                          )}

                          {/* Bot Messages */}
                          {message.bot_message && (message.sent !== false || message.isTemp) && (
                            <Box 
                              sx={{ 
                                position: 'relative', 
                                width: 'fit-content',
                                maxWidth: '80%',
                                alignSelf: 'flex-end',
                                mr: 1,
                              }}
                            >
                              {message.author_full_name && (
                                <Typography
                                  variant="caption"
                                  sx={{
                                    color: theme.palette.primary.main,
                                    fontSize: '0.7rem',
                                    mb: 1,
                                    textAlign: 'right', // Align the author's name to the right
                                  }}
                                >
                                  {message.author_full_name}
                                </Typography>
                              )}
                              <MessagePaper 
                                elevation={1} 
                                isBot={true} 
                                isWhatsApp={whatsapp || message.whatsapp}
                              >
                                <MarkdownContent content={formatMessageContent(message.bot_message)} />
                                <Typography
                                  variant="caption"
                                  sx={{
                                    position: 'absolute',
                                    right: 8,
                                    bottom: 4,
                                    color: (whatsapp || message.whatsapp) ? 'rgba(0, 0, 0, 0.6)' : 'rgba(255, 255, 255, 0.7)',
                                    fontSize: '0.7rem',
                                  }}
                                >
                                  {time}
                                </Typography>
                                {message.car_stocks && Array.isArray(message.car_stocks) && message.car_stocks.length > 0 && (
                                  isSmallScreen ? (
                                    <MobileCarCarousel cars={message.car_stocks} />
                                  ) : (
                                    <CarAttachmentsContainer>
                                      {message.car_stocks.map((car) => (
                                        <CarStockCard
                                          key={car.vehicleid}
                                          car={car}
                                          isSmallScreen={isSmallScreen}
                                        />
                                      ))}
                                    </CarAttachmentsContainer>
                                  )
                                )}
                              </MessagePaper>
                            </Box>
                          )}

                          {/* Message actions - align with their respective messages */}
                          {!message.isTemp && (
                            <Box
                              sx={{
                                display: 'flex',
                                justifyContent: message.sender_message ? 'flex-start' : 'flex-end',
                                width: '100%',
                                mt: 0.5,
                                mb: 1,
                                px: 1,
                              }}
                            >
                              {canEdit && false &&(
                                <>
                                  <Tooltip title="Helpful">
                                    <IconButton
                                      size="small"
                                      onClick={() => handleFeedback(message.id, 'helpful')}
                                    >
                                      <ThumbUp fontSize="small" />
                                    </IconButton>
                                  </Tooltip>
                                  <Tooltip title="Not Helpful">
                                    <IconButton
                                      size="small"
                                      onClick={() => handleFeedback(message.id, 'not_helpful')}
                                    >
                                      <ThumbDown fontSize="small" />
                                    </IconButton>
                                  </Tooltip>
                                  <Tooltip title="Add Comment">
                                    <IconButton
                                      size="small"
                                      onClick={() => handleFeedback(message.id, 'comment')}
                                    >
                                      <CommentIcon fontSize="small" />
                                    </IconButton>
                                  </Tooltip>
                                </>
                              )}
                            </Box>
                          )}
                        </Box>
                      </ListItem>
                    </motion.div>
                  </React.Fragment>
                );
              })}
          </AnimatePresence>
        </MessageList>

        {/* Fixed Bottom Input Section - Only show if humanControl is true */}
        {canEdit && humanControl && (
          <FixedBottomBox>
            <SendMessageForm
              onSendMessage={handleSendMessage}
              unsentMessage={unsentMessage}
              onPredictMessage={handlePredictMessage}
              isPredicting={isPredicting}
              isCurrentUserController={isCurrentUserController}
              humanControl={humanControl}
            />
          </FixedBottomBox>
        )}

        {/* Show a message when human control is not activated */}
        {canEdit && !humanControl && (
          <FixedBottomBox>
            <Typography variant="body2" color="text.secondary" align="center" sx={{ py: 1 }}>
              Human control must be activated to send messages
            </Typography>
          </FixedBottomBox>
        )}

        {/* Summary Dialog for mobile */}
        <Dialog
          fullScreen
          open={summaryDialogOpen}
          onClose={() => setSummaryDialogOpen(false)}
          TransitionComponent={Fade}
        >
          <Box sx={{
            p: 2,
            backgroundColor: theme.palette.background.paper,
            borderBottom: `1px solid ${theme.palette.divider}`,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}>
            <Typography variant="h6">Informazioni Chat</Typography>
            <IconButton
              edge="end"
              color="inherit"
              onClick={() => setSummaryDialogOpen(false)}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
          </Box>
          <DialogContent>
            <SummarySection summary={chatSummary} />
          </DialogContent>
        </Dialog>
      </StyledPaper>

      {/* Dialogs and Snackbars */}
      <Dialog
        open={feedbackDialog.open}
        onClose={() => setFeedbackDialog({ open: false, messageId: null })}
      >
        {/* ... Dialog content ... */}
      </Dialog>

      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={() => setSnackbar({ ...snackbar, open: false })}
      >
        <Alert severity={snackbar.type}>{snackbar.message}</Alert>
      </Snackbar>
    </Container>
  );
};

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export default ChatHistoryDetail;
