import React from 'react';
import { 
  Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, 
  IconButton, Typography, Chip, Stack, Tooltip
} from '@mui/material';
import { Delete as DeleteIcon, Edit as EditIcon } from '@mui/icons-material';
import { useAuth } from '../../../contexts/AuthContext';

const HolidayList = ({ holidays, handleEditHoliday, handleDeleteHoliday, services, locations = [] }) => {
  const { userRole, appAccess } = useAuth();
  const permissions = appAccess['booking'] || {};
  const canEdit = permissions.can_edit || userRole === 'superadmin';
  const canDelete = permissions.can_delete || userRole === 'superadmin';

  return (
    <TableContainer 
      component={Paper} 
      sx={{ 
        boxShadow: 3,
        borderRadius: 2,
        '& .MuiTable-root': {
          borderCollapse: 'separate',
          borderSpacing: '0 8px'
        }
      }}
    >
      <Table>
        <TableHead>
          <TableRow>
            <TableCell sx={{ fontWeight: 'bold', fontSize: '1.1rem' }}>Name</TableCell>
            <TableCell sx={{ fontWeight: 'bold', fontSize: '1.1rem' }}>Start Date</TableCell>
            <TableCell sx={{ fontWeight: 'bold', fontSize: '1.1rem' }}>End Date</TableCell>
            <TableCell sx={{ fontWeight: 'bold', fontSize: '1.1rem' }}>Services</TableCell>
            <TableCell sx={{ fontWeight: 'bold', fontSize: '1.1rem' }}>Cities</TableCell>
            <TableCell sx={{ fontWeight: 'bold', fontSize: '1.1rem' }}>Actions</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {holidays.map((holiday) => (
            <TableRow 
              key={holiday.id}
              sx={{
                '&:hover': {
                  backgroundColor: 'rgba(0, 0, 0, 0.04)',
                  transition: 'background-color 0.2s ease'
                }
              }}
            >
              <TableCell>
                <Typography variant="subtitle1" fontWeight="medium">
                  {holiday.name}
                </Typography>
              </TableCell>
              <TableCell>{new Date(holiday.start_date).toLocaleDateString()}</TableCell>
              <TableCell>{new Date(holiday.end_date).toLocaleDateString()}</TableCell>
              <TableCell>
                <Stack direction="row" spacing={1} flexWrap="wrap">
                  {(holiday.services || []).map((serviceId) => {
                    const service = services.find((s) => s.id === serviceId);
                    return service ? (
                      <Chip 
                        key={serviceId}
                        label={service.name}
                        size="small"
                        sx={{ margin: '2px' }}
                      />
                    ) : null;
                  })}
                </Stack>
              </TableCell>
              <TableCell>
                <Stack direction="row" spacing={1} flexWrap="wrap">
                  {(holiday.location || []).map(locationId => {
                    const location = locations.find(l => l.id === locationId);
                    return location ? (
                      <Chip 
                        key={locationId}
                        label={location.citta}
                        size="small"
                        variant="outlined"
                        sx={{ margin: '2px' }}
                      />
                    ) : null;
                  })}
                </Stack>
              </TableCell>
              <TableCell>
                {canEdit && (
                  <Tooltip title="Edit Holiday">
                    <IconButton onClick={() => handleEditHoliday(holiday)} color="primary" size="small">
                      <EditIcon />
                    </IconButton>
                  </Tooltip>
                )}
                {canDelete && (
                  <Tooltip title="Delete Holiday">
                    <IconButton onClick={() => handleDeleteHoliday(holiday.id)} color="error" size="small">
                      <DeleteIcon />
                    </IconButton>
                  </Tooltip>
                )}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default HolidayList;
