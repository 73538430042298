import React from 'react';
import { Grid, TextField, Button, FormControl, Select, MenuItem, InputLabel, Paper, Typography, Checkbox, ListItemText } from '@mui/material';
import { useAuth } from '../../../contexts/AuthContext';

const AvailabilityForm = ({
  availabilityFormData,
  handleAvailabilityFormChange,
  handleAvailabilityFormSubmit,
  isEditingAvailability,
  dayOfWeekOptions,
  locations = [],
}) => {
  console.log('Form Data:', availabilityFormData);
  console.log('Locations array:', locations);

  const { userRole, appAccess } = useAuth(); // Access user role and permissions
  const permissions = appAccess['booking_settings'] || {}; // Use booking_settings for permissions
  const setting_canCreate = permissions.can_create || userRole === 'superadmin'; // Create permission
  const setting_canEdit = permissions.can_edit || userRole === 'superadmin'; // Edit permission

  // Determine if the user has permission to either create or edit
  const canSubmitForm = isEditingAvailability ? setting_canEdit : setting_canCreate;

  // Fix: Change locations to location to match the API data structure
  const formLocations = Array.isArray(availabilityFormData.location) 
    ? availabilityFormData.location 
    : (availabilityFormData.locations || []); // Fallback to check both property names

  console.log('availabilityFormData:', availabilityFormData);
  console.log('Calculated formLocations:', formLocations);

  return (
    <Paper elevation={3} style={{ padding: '20px', marginBottom: '20px' }}>
      <Typography variant="h6" gutterBottom>
        {isEditingAvailability ? 'Edit Availability' : 'Add New Availability'}
      </Typography>
      <form onSubmit={handleAvailabilityFormSubmit}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <FormControl fullWidth>
              <InputLabel id="day-of-week-label">
                {isEditingAvailability ? 'Day of Week' : 'Days of Week'}
              </InputLabel>
              <Select
                labelId="day-of-week-label"
                multiple={!isEditingAvailability}
                name="day_of_week"
                value={Array.isArray(availabilityFormData.day_of_week) ? availabilityFormData.day_of_week : []}
                onChange={handleAvailabilityFormChange}
                renderValue={(selected) => 
                  selected
                    .map(value => dayOfWeekOptions.find(option => option.value === parseInt(value))?.label)
                    .filter(Boolean)
                    .join(', ')
                }
                required
                disabled={!canSubmitForm}
              >
                {dayOfWeekOptions.map((option) => (
                  <MenuItem 
                    key={option.value} 
                    value={option.value}
                  >
                    <Checkbox 
                      checked={availabilityFormData.day_of_week.indexOf(option.value.toString()) > -1}
                    />
                    <ListItemText primary={option.label} />
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={3}>
            <TextField
              name="start_time"
              label="Start Time"
              type="time"
              value={availabilityFormData.start_time}
              onChange={handleAvailabilityFormChange}
              fullWidth
              required
              disabled={!canSubmitForm} // Disable if no permission
            />
          </Grid>
          <Grid item xs={12} sm={3}>
            <TextField
              name="end_time"
              label="End Time"
              type="time"
              value={availabilityFormData.end_time}
              onChange={handleAvailabilityFormChange}
              fullWidth
              required
              disabled={!canSubmitForm} // Disable if no permission
            />
          </Grid>
          <Grid item xs={12} sm={3}>
            <TextField
              name="max_slots"
              label="Max Slots"
              type="number"
              value={availabilityFormData.max_slots}
              onChange={handleAvailabilityFormChange}
              fullWidth
              required
              disabled={!canSubmitForm} // Disable if no permission
            />
          </Grid>
          <Grid item xs={12}>
            <FormControl fullWidth disabled={!canSubmitForm} required>
              <InputLabel id="select-multiple-locations-label">Select Locations</InputLabel>
              <Select
                labelId="select-multiple-locations-label"
                multiple
                name="location"
                value={formLocations}
                onChange={handleAvailabilityFormChange}
                renderValue={(selected) =>
                  selected
                    .map((locationId) => {
                      const location = locations.find((l) => l.id === locationId);
                      return location ? location.citta : '';
                    })
                    .filter(Boolean)
                    .join(', ')
                }
                error={!Array.isArray(formLocations) || formLocations.length === 0}
              >
                {locations.map((location) => (
                  <MenuItem key={location.id} value={location.id}>
                    <Checkbox checked={formLocations.indexOf(location.id) > -1} />
                    <ListItemText primary={location.citta} />
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            {canSubmitForm && ( // Show the button only if the user has permission to submit
              <Button type="submit" variant="contained" color="primary">
                {isEditingAvailability ? 'Update Availability' : 'Add Availability'}
              </Button>
            )}
          </Grid>
        </Grid>
      </form>
    </Paper>
  );
};

export default AvailabilityForm;
