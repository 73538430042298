import React, { useState, useEffect, useCallback } from 'react';
import { DataGrid } from '@mui/x-data-grid';
import { 
    Box, Typography, TextField, Button, CssBaseline, Grid, 
    createTheme, ThemeProvider, Dialog, DialogActions, 
    DialogContent, DialogContentText, DialogTitle,
    CircularProgress, Input, Snackbar, Alert, Tooltip, IconButton,
    Accordion, AccordionSummary, AccordionDetails, List, ListItem, ListItemText,
    Fade, Grow, Zoom, Paper, InputAdornment
} from '@mui/material';
import { alpha } from '@mui/material/styles';
import { itIT } from '@mui/material/locale';
import { fetchCompanies, createCompany, updateCompany, deleteCompany, fetchCurrentUserRole, uploadLogo, getTaskProgress } from '../components/api/company';
import { MapPin, Mail, Building, Edit, Trash2, Plus, X, ChevronDown, Phone, Upload, Info, Bot, Bell, Link } from 'lucide-react';
import AIManagement from '../components/aimanagement/AiManagement';

const theme = createTheme({
    palette: {
        mode: 'light',
        primary: { main: '#3a86ff' },
        secondary: { main: '#ff006e' },
        background: { 
            default: '#f8fafc',
            paper: '#ffffff' 
        },
    },
    typography: {
        fontFamily: '"Poppins", "Roboto", "Helvetica", "Arial", sans-serif',
        h2: {
            fontWeight: 800,
            fontSize: '2.75rem',
            background: 'linear-gradient(135deg, #3a86ff 0%, #ff006e 100%)',
            WebkitBackgroundClip: 'text',
            WebkitTextFillColor: 'transparent',
            letterSpacing: '-0.02em',
        },
    },
    components: {
        MuiButton: {
            styleOverrides: {
                root: {
                    borderRadius: 12,
                    textTransform: 'none',
                    fontWeight: 600,
                    padding: '8px 20px',
                    transition: 'all 0.2s cubic-bezier(0.4, 0, 0.2, 1)',
                    '&:hover': {
                        transform: 'translateY(-2px)',
                        boxShadow: '0 6px 20px rgba(58, 134, 255, 0.15)',
                    },
                },
            },
        },
        MuiPaper: {
            styleOverrides: {
                root: {
                    borderRadius: 16,
                    boxShadow: '0 4px 20px rgba(0, 0, 0, 0.08)',
                    transition: 'all 0.3s ease-in-out',
                    '&:hover': {
                        boxShadow: '0 8px 30px rgba(0, 0, 0, 0.12)',
                    },
                },
            },
        },
        MuiDataGrid: {
            styleOverrides: {
                root: {
                    border: 'none',
                    '& .MuiDataGrid-cell:focus': {
                        outline: 'none',
                    },
                    '& .MuiDataGrid-row': {
                        transition: 'all 0.2s ease',
                        cursor: 'pointer',
                        '&:hover': {
                            backgroundColor: alpha('#3a86ff', 0.08),
                            transform: 'translateY(-2px)',
                            boxShadow: '0 4px 12px rgba(0,0,0,0.1)',
                        },
                    },
                    '& .MuiDataGrid-columnHeaders': {
                        backgroundColor: alpha('#3a86ff', 0.04),
                        borderRadius: '8px 8px 0 0',
                        position: 'sticky',
                        top: 0,
                        zIndex: 2,
                    },
                    '& .MuiDataGrid-virtualScroller': {
                        '& .MuiDataGrid-row:nth-of-type(odd)': {
                            backgroundColor: alpha('#f8fafc', 0.5),
                        },
                    },
                    '& .MuiDataGrid-cell': {
                        borderBottom: '1px solid rgba(58, 134, 255, 0.08)',
                    },
                    '& .MuiDataGrid-footerContainer': {
                        borderTop: 'none',
                        backgroundColor: alpha('#3a86ff', 0.02),
                        borderRadius: '0 0 8px 8px',
                    },
                },
            },
        },
    },
}, itIT);

async function urlBase64ToUint8Array(base64String) {
  const padding = '='.repeat((4 - base64String.length % 4) % 4);
  const base64 = (base64String + padding)
    .replace(/\-/g, '+')
    .replace(/_/g, '/');

  const rawData = window.atob(base64);
  const outputArray = new Uint8Array(rawData.length);

  for (let i = 0; i < rawData.length; ++i) {
    outputArray[i] = rawData.charCodeAt(i);
  }
  return outputArray;
}

const PaginaAziende = () => {
    const [rows, setRows] = useState([]);
    const [open, setOpen] = useState(false);
    const [currentCompany, setCurrentCompany] = useState({
        name: '', codice_concessionario: "", email: '', link_gdpr: '', link_website: '',
        link_marketing_consent: '',
        sitemap_link: '',
        locations: []
    });
    const [currentUser, setCurrentUser] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [confirmDeleteOpen, setConfirmDeleteOpen] = useState(false);
    const [companyToDelete, setCompanyToDelete] = useState(null);
    const [logoFile, setLogoFile] = useState(null);
    const [validationErrors, setValidationErrors] = useState({});
    const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'success' });
    const [openAIDialog, setOpenAIDialog] = useState(false);
    const [selectedCompanyId, setSelectedCompanyId] = useState(null);
    const [notificationPermission, setNotificationPermission] = useState(Notification.permission);
    const [subscription, setSubscription] = useState(null);
    const [isSubscribed, setIsSubscribed] = useState(false);

    const showSnackbar = useCallback((message, severity) => {
        setSnackbar({ open: true, message, severity });
    }, []);

    const fetchData = useCallback(async () => {
        setIsLoading(true);
        try {
            const [companiesData, userData] = await Promise.all([
                fetchCompanies(),
                fetchCurrentUserRole()
            ]);
            
            setCurrentUser(userData.role);
            setRows(userData.role === 'superadmin' ? companiesData : companiesData && companiesData.length > 0 ? [companiesData[0]] : []);
        } catch (error) {
            console.error('Error fetching data:', error);
            showSnackbar('Error fetching data. Please try again.', 'error');
        } finally {
            setIsLoading(false);
        }
    }, [showSnackbar]);

    useEffect(() => {
        fetchData();
    }, [fetchData]);

    const handleOpenAIDialog = useCallback((companyId) => {
        setSelectedCompanyId(companyId);
        setOpenAIDialog(true);
    }, []);

    const handleCloseAIDialog = useCallback(() => {
        setOpenAIDialog(false);
        setSelectedCompanyId(null);
    }, []);

    const validateForm = useCallback(() => {
        const errors = {};
        if (!currentCompany.name) errors.name = 'Name is required';
        if (!currentCompany.email) errors.email = 'Email is required';
        else if (!/\S+@\S+\.\S+/.test(currentCompany.email)) errors.email = 'Email is invalid';
        if (!currentCompany.link_marketing_consent) errors.link_marketing_consent = 'Marketing consent link is required';
        if (!currentCompany.sitemap_link) errors.sitemap_link = 'Sitemap link is required';
        if (!currentCompany.link_website) errors.link_website = 'Website link is required';
        if (!currentCompany.link_gdpr) errors.link_gdpr = 'GDPR link is required';
        
        setValidationErrors(errors);
        return Object.keys(errors).length === 0;
    }, [currentCompany]);

    const handleAdd = useCallback(() => {
        if (currentUser !== 'superadmin') {
            showSnackbar('Only superadmins can create new companies', 'warning');
            return;
        }
        setCurrentCompany({
            name: '', codice_concessionario: "", email: '', link_gdpr: '', link_website: '',
            link_marketing_consent: '',
            sitemap_link: '',
            locations: []
        });
        setLogoFile(null);
        setOpen(true);
    }, [currentUser]);

    const handleEdit = useCallback((row) => {
        if (currentUser !== 'superadmin' && currentUser !== 'admin') {
            showSnackbar('Only superadmins and admins can edit companies', 'warning');
            return;
        }
        setCurrentCompany(row);
        setLogoFile(null);
        setOpen(true);
    }, [currentUser]);

    const handleDelete = useCallback((id) => {
        if (currentUser !== 'superadmin') {
            showSnackbar('Only superadmins can delete companies', 'warning');
            return;
        }
        setCompanyToDelete(id);
        setConfirmDeleteOpen(true);
    }, [currentUser]);

    const confirmDelete = useCallback(async () => {
        if (companyToDelete) {
            setIsLoading(true);
            try {
                await deleteCompany(companyToDelete);
                setRows(prevRows => prevRows.filter((row) => row.id !== companyToDelete));
                showSnackbar('Company deleted successfully', 'success');
            } catch (error) {
                console.error('Error deleting company:', error);
                showSnackbar('Error deleting company', 'error');
            } finally {
                setIsLoading(false);
                setConfirmDeleteOpen(false);
                setCompanyToDelete(null);
            }
        }
    }, [companyToDelete]);

    const handleClose = useCallback(() => {
        setOpen(false);
        setCurrentCompany({
            name: '', email: '', codice_concessionario: "", link_gdpr: '', link_website: '',
            link_marketing_consent: '',
            sitemap_link: '',
            locations: []
        });
        setLogoFile(null);
        setValidationErrors({});
    }, []);

    const handleSave = useCallback(async () => {
        if (!validateForm()) return;

        setIsLoading(true);
        try {
            let updatedCompany;
            if (currentCompany.id) {
                console.log('Updating company with data:', currentCompany);
                
                const updateData = {
                    name: currentCompany.name,
                    codice_concessionario: currentCompany.codice_concessionario,
                    email: currentCompany.email,
                    link_gdpr: currentCompany.link_gdpr,
                    link_website: currentCompany.link_website,
                    link_marketing_consent: currentCompany.link_marketing_consent,
                    sitemap_link: currentCompany.sitemap_link,
                    locations: currentCompany.locations.map(location => ({
                        email: location.email,
                        numero_di_telefono: location.numero_di_telefono,
                        citta: location.citta,
                        inidirizzo: location.inidirizzo,
                        cap: location.cap
                    }))
                };
                
                const cleanedData = Object.fromEntries(
                    Object.entries(updateData)
                        .filter(([_, v]) => v != null && v !== '')
                );
                
                console.log('Cleaned data being sent:', cleanedData);
                
                updatedCompany = await updateCompany(currentCompany.id, cleanedData);
                console.log('Update response:', updatedCompany);
                
                if (logoFile) {
                    try {
                        const logoResponse = await uploadLogo(currentCompany.id, logoFile);
                        updatedCompany = { ...updatedCompany, logo_url: logoResponse.logo_url };
                    } catch (logoError) {
                        console.error('Error uploading logo:', logoError);
                    }
                }

                setRows(prevRows => 
                    prevRows.map(row => 
                        row.id === updatedCompany.id ? updatedCompany : row
                    )
                );
                showSnackbar('Company updated successfully', 'success');
            } else {
                updatedCompany = await createCompany(currentCompany);
                
                if (logoFile) {
                    try {
                        const logoResponse = await uploadLogo(updatedCompany.id, logoFile);
                        updatedCompany = { ...updatedCompany, logo_url: logoResponse.logo_url };
                    } catch (logoError) {
                        console.error('Error uploading logo:', logoError);
                    }
                }

                setRows(prevRows => [...prevRows, updatedCompany]);
                showSnackbar('Company created successfully', 'success');
            }
            
            handleClose();
        } catch (error) {
            console.error('Error details:', error.response?.data);
            const errorMessage = error.response?.data?.message || 
                               error.response?.data?.error || 
                               error.message || 
                               'Error saving company';
            showSnackbar(errorMessage, 'error');
        } finally {
            setIsLoading(false);
        }
    }, [currentCompany, logoFile, validateForm, handleClose, showSnackbar]);

    const handleInputChange = useCallback((e) => {
        const { name, value } = e.target;
        setCurrentCompany(prevCompany => ({ ...prevCompany, [name]: value }));
    }, []);

    const handleLogoChange = (event) => {
        const file = event.target.files[0];
        if (file) {
            setLogoFile(file);
        }
    };

    const handleLocationChange = useCallback((index, field, value) => {
        setCurrentCompany(prevCompany => {
            const updatedLocations = [...prevCompany.locations];
            updatedLocations[index] = { ...updatedLocations[index], [field]: value };
            return { ...prevCompany, locations: updatedLocations };
        });
    }, []);

    const addLocation = useCallback(() => {
        setCurrentCompany(prevCompany => ({
            ...prevCompany,
            locations: [
                ...prevCompany.locations,
                { email: '', numero_di_telefono: '', citta: '', inidirizzo: '', cap: '' }
            ]
        }));
    }, []);

    const removeLocation = useCallback((index) => {
        setCurrentCompany(prevCompany => ({
            ...prevCompany,
            locations: prevCompany.locations.filter((_, i) => i !== index)
        }));
    }, []);

    const columns = [
        { field: 'name', headerName: 'Nome', flex: 1 },
        { field: 'email', headerName: 'Email', flex: 1 },
        {
            field: 'car_stock_sync_status',
            headerName: 'Stock status',
            flex: 1,
            renderCell: (params) => (
                <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                    <CircularProgress 
                        variant="determinate" 
                        value={params.row.car_stock_sync_status === 'COMPLETED' ? 100 : 
                               params.row.car_stock_sync_status === 'PROCESSING' ? 50 : 
                               params.row.car_stock_sync_status === 'FAILED' ? 100 : 0}
                        size={20}
                        sx={{ 
                            color: params.row.car_stock_sync_status === 'COMPLETED' ? 'success.main' : 
                                   params.row.car_stock_sync_status === 'PROCESSING' ? 'primary.main' : 
                                   params.row.car_stock_sync_status === 'FAILED' ? 'error.main' : 
                                   'text.disabled'
                        }}
                    />
                    
                    <Typography variant="caption">
                    {params.row.car_stock_sync_status || 'NOT_STARTED'} 
                    </Typography>
                </Box>
            )
        },
        {
            field: 'last_updates',
            headerName: 'Stock sync',
            flex: 1.5,
            renderCell: (params) => (
                <Box>
                    {params.row.last_car_stock_task_update && (
                        <Typography variant="caption" display="block">
                            {new Date(params.row.last_car_stock_task_update).toLocaleString()}
                        </Typography>
                    )}
                </Box>
            )
        },
        

{
    field: 'document_sync_status',
    headerName: 'Knowledge status',
    flex: 1,
    renderCell: (params) => (
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
            <CircularProgress 
                variant="determinate" 
                value={params.row.document_sync_status === 'COMPLETED' ? 100 : 
                       params.row.document_sync_status === 'PROCESSING' ? 50 : 
                       params.row.document_sync_status === 'FAILED' ? 100 : 0}
                size={20}
                sx={{ 
                    color: params.row.document_sync_status === 'COMPLETED' ? 'success.main' : 
                           params.row.document_sync_status === 'PROCESSING' ? 'primary.main' : 
                           params.row.document_sync_status === 'FAILED' ? 'error.main' : 
                           'text.disabled'
                }}
            />
            
            <Typography variant="caption">
            {params.row.document_sync_status || 'NOT_STARTED'} 
            </Typography>
        </Box>
    )
},
{
    field: 'last_document_task_update',
    headerName: 'Knowledge sync',
    flex: 1.5,
    renderCell: (params) => (
        <Box>
            {params.row.last_document_task_update && (
                <Typography variant="caption" display="block">
                    {new Date(params.row.last_document_task_update).toLocaleString()}
                </Typography>
            )}
        </Box>
    )
},
        {
            field: 'actions',
            headerName: 'Azioni',
            flex: 1,
            renderCell: (params) => (
                <Box>
                    <Tooltip title="Edit">
                        <IconButton 
                            onClick={() => handleEdit(params.row)}
                            disabled={currentUser !== 'superadmin' && currentUser !== 'admin'}
                        >
                            <Edit size={20} />
                        </IconButton>
                    </Tooltip>
                    <Tooltip title="Delete">
                        <IconButton 
                            onClick={() => handleDelete(params.row.id)}
                            disabled={currentUser !== 'superadmin'}
                        >
                            <Trash2 size={20} />
                        </IconButton>
                    </Tooltip>
                </Box>
            ),
        },
        {
            field: 'ai',
            headerName: 'Manage AI',
            flex: 1,
            renderCell: (params) => (
                <Tooltip title="Manage AI">
                    <IconButton 
                        onClick={() => handleOpenAIDialog(params.row.id)}
                        disabled={currentUser !== 'superadmin' && currentUser !== 'admin'}
                    >
                        <Bot size={20} />
                    </IconButton>
                </Tooltip>
            ),
        },
    ];

    const CompanyCard = ({ company, currentUser, onEdit }) => {
        if (!company) return null;

        return (
            <Grow in={true} timeout={1000}>
                <Box sx={{ 
                    bgcolor: 'background.paper', 
                    borderRadius: 4, 
                    overflow: 'hidden', 
                    boxShadow: '0 10px 40px rgba(0,0,0,0.12)', 
                    maxWidth: "100%", 
                    margin: 'auto',
                    position: 'relative',
                    '&::before': {
                        content: '""',
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        right: 0,
                        height: 180,
                        background: 'linear-gradient(135deg, #3a86ff 0%, #ff006e 100%)',
                        zIndex: 0,
                    }
                }}>
                    <Box sx={{ 
                        height: 180, 
                        position: 'relative',
                        zIndex: 1,
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                    }}>
                        {company.logo_url ? (
                            <Zoom in={true} timeout={1500}>
                                <Box
                                    component="img"
                                    src={company.logo_url}
                                    alt={`${company.name} logo`}
                                    sx={{
                                        width: "50%",
                                        height: "50%",
                                        objectFit: 'contain',
                                        filter: 'drop-shadow(0px 4px 8px rgba(0,0,0,0.2))',
                                    }}
                                />
                            </Zoom>
                        ) : (
                            <Typography variant="h4" sx={{ color: 'white' }}>
                                {company.name.charAt(0)}
                            </Typography>
                        )}
                    </Box>
                    <Box sx={{ pt: 3, pb: 3, px: 3 }}>
                        <Typography variant="h5" align="center" gutterBottom>{company.name}</Typography>
                        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1, mt: 2 }}>
                            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                <Mail size={18} style={{ marginRight: 8 }} />
                                <Typography 
                                    variant="body2" 
                                    component="a" 
                                    href={`mailto:${company.email}`}
                                    sx={{ color: 'primary.main', textDecoration: 'none', '&:hover': { textDecoration: 'underline' } }}
                                >
                                    {company.email}
                                </Typography>
                            </Box>
                        </Box>
                        {company.locations && company.locations.length > 0 && (
                            <Accordion 
                                sx={{ 
                                    mt: 3,
                                    '&:before': { display: 'none' },
                                    background: 'transparent',
                                    boxShadow: 'none'
                                }}
                            >
                                <AccordionSummary 
                                    expandIcon={<ChevronDown />}
                                    sx={{
                                        background: 'linear-gradient(135deg, rgba(58, 134, 255, 0.08) 0%, rgba(255, 0, 110, 0.08) 100%)',
                                        borderRadius: '12px',
                                        '&.Mui-expanded': {
                                            borderBottomLeftRadius: 0,
                                            borderBottomRightRadius: 0,
                                        }
                                    }}
                                >
                                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                                        <MapPin size={18} />
                                        <Typography sx={{ fontWeight: 600 }}>
                                            Locations ({company.locations.length})
                                        </Typography>
                                    </Box>
                                </AccordionSummary>
                                <AccordionDetails sx={{ 
                                    p: 0,
                                    background: 'rgba(255, 255, 255, 0.8)',
                                    borderBottomLeftRadius: '12px',
                                    borderBottomRightRadius: '12px'
                                }}>
                                    <List sx={{ p: 0 }}>
                                        {company.locations.map((location, index) => (
                                            <ListItem 
                                                key={index}
                                                sx={{
                                                    borderBottom: index !== company.locations.length - 1 ? '1px solid rgba(0,0,0,0.08)' : 'none',
                                                    transition: 'all 0.2s ease',
                                                    '&:hover': {
                                                        background: 'rgba(58, 134, 255, 0.04)',
                                                    }
                                                }}
                                            >
                                                <Box sx={{ width: '100%', p: 1 }}>
                                                    <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 1 }}>
                                                        <Typography variant="subtitle1" sx={{ fontWeight: 600, color: 'primary.main' }}>
                                                            {location.citta}
                                                        </Typography>
                                                        {location.partita_iva && (
                                                            <Typography variant="caption" sx={{ color: 'text.secondary' }}>
                                                                P.IVA: {location.partita_iva}
                                                            </Typography>
                                                        )}
                                                    </Box>
                                                    
                                                    <Grid container spacing={2} sx={{ color: 'text.secondary' }}>
                                                        <Grid item xs={12} sm={6}>
                                                            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, mb: 0.5 }}>
                                                                <MapPin size={16} />
                                                                <Typography variant="body2">
                                                                    {location.inidirizzo}, {location.cap}
                                                                </Typography>
                                                            </Box>
                                                        </Grid>
                                                        
                                                        {location.email && (
                                                            <Grid item xs={12} sm={6}>
                                                                <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, mb: 0.5 }}>
                                                                    <Mail size={16} />
                                                                    <Typography 
                                                                        variant="body2"
                                                                        component="a"
                                                                        href={`mailto:${location.email}`}
                                                                        sx={{ 
                                                                            color: 'primary.main',
                                                                            textDecoration: 'none',
                                                                            '&:hover': { textDecoration: 'underline' }
                                                                        }}
                                                                    >
                                                                        {location.email}
                                                                    </Typography>
                                                                </Box>
                                                            </Grid>
                                                        )}
                                                        
                                                        {location.numero_di_telefono && (
                                                            <Grid item xs={12} sm={6}>
                                                                <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                                                                    <Phone size={16} />
                                                                    <Typography 
                                                                        variant="body2"
                                                                        component="a"
                                                                        href={`tel:${location.numero_di_telefono}`}
                                                                        sx={{ 
                                                                            color: 'primary.main',
                                                                            textDecoration: 'none',
                                                                            '&:hover': { textDecoration: 'underline' }
                                                                        }}
                                                                    >
                                                                        {location.numero_di_telefono}
                                                                    </Typography>
                                                                </Box>
                                                            </Grid>
                                                        )}
                                                    </Grid>
                                                </Box>
                                            </ListItem>
                                        ))}
                                    </List>
                                </AccordionDetails>
                            </Accordion>
                        )}
                        {currentUser === 'admin' && (
                            <Button
                                variant="contained"
                                onClick={() => onEdit(company)}
                                startIcon={<Edit />}
                                fullWidth
                                sx={{ mt: 3 }}
                            >
                                Modifica Azienda
                            </Button>
                        )}
                    </Box>
                    
                    {/* Update sync status section */}
                    <Box sx={{ mt: 3, px: 3 }}>
                        <Typography variant="h6" gutterBottom>Sync Status</Typography>
                        
                        <Box sx={{ mb: 2 }}>
                            <Typography variant="subtitle2">Car Stock Sync</Typography>
                            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                                <CircularProgress 
                                    variant="determinate" 
                                    value={company.car_stock_sync_status === 'COMPLETED' ? 100 : 
                                           company.car_stock_sync_status === 'PROCESSING' ? 50 : 
                                           company.car_stock_sync_status === 'FAILED' ? 100 : 0}
                                    size={24}
                                    sx={{ 
                                        color: company.car_stock_sync_status === 'COMPLETED' ? 'success.main' : 
                                               company.car_stock_sync_status === 'PROCESSING' ? 'primary.main' : 
                                               company.car_stock_sync_status === 'FAILED' ? 'error.main' : 
                                               'text.disabled'
                                    }}
                                />
                                <Typography variant="body2">
                                    {company.car_stock_sync_status || 'NOT_STARTED'}
                                </Typography>
                            </Box>
                            {company.last_car_stock_task_update && (
                                <Typography variant="caption" color="text.secondary">
                                    Last sync: {new Date(company.last_car_stock_task_update).toLocaleString()}
                                </Typography>
                            )}
                        </Box>
                    </Box>
                </Box>
            </Grow>
        );
    };

    const unsubscribeFromNotifications = useCallback(async () => {
        try {
            if (!subscription) {
                throw new Error('No active subscription found');
            }

            const token = localStorage.getItem('token');
            if (!token) {
                throw new Error('Authentication token not found');
            }

            // Unsubscribe from push manager first
            await subscription.unsubscribe();

            // Then remove from server
            const response = await fetch('https://admin.aitomotivelab.com/api/subscription/', {
                method: 'DELETE',
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            });

            if (!response.ok) {
                const errorData = await response.json();
                throw new Error(errorData.error || 'Failed to remove subscription');
            }

            setSubscription(null);
            setIsSubscribed(false);
            showSnackbar('Successfully unsubscribed from notifications', 'success');

        } catch (error) {
            console.error('Error unsubscribing:', error);
            showSnackbar(error.message, 'error');
        }
    }, [subscription, showSnackbar]);

    const subscribeToNotifications = useCallback(async () => {
        try {
            if (!('serviceWorker' in navigator) || !('PushManager' in window)) {
                throw new Error('Push notifications are not supported in this browser');
            }

            const token = localStorage.getItem('token');
            if (!token) {
                throw new Error('Authentication token not found');
            }

            const permission = await Notification.requestPermission();
            setNotificationPermission(permission);
            
            if (permission !== 'granted') {
                throw new Error('Notification permission was not granted');
            }

            // Register or get existing service worker
            const registration = await navigator.serviceWorker.register('/service-worker.js', {
                scope: '/'
            });

            // Get VAPID public key
            const vapidResponse = await fetch('https://admin.aitomotivelab.com/api/vapid-public-key/', {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            });
            
            if (!vapidResponse.ok) {
                throw new Error('Failed to fetch VAPID key');
            }

            const { public_key } = await vapidResponse.json();
            const convertedVapidKey = await urlBase64ToUint8Array(public_key);

            // Subscribe to push notifications
            const pushSubscription = await registration.pushManager.subscribe({
                userVisibleOnly: true,
                applicationServerKey: convertedVapidKey
            });

            // Format subscription data according to API requirements
            const subscriptionData = {
                endpoint: pushSubscription.endpoint,
                keys: {
                    p256dh: btoa(String.fromCharCode(...new Uint8Array(pushSubscription.getKey('p256dh')))),
                    auth: btoa(String.fromCharCode(...new Uint8Array(pushSubscription.getKey('auth'))))
                }
            };

            // Save subscription to server
            const saveResponse = await fetch('https://admin.aitomotivelab.com/api/subscription/', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
                body: JSON.stringify(subscriptionData)
            });

            if (!saveResponse.ok) {
                // If server save fails, unsubscribe from push manager
                await pushSubscription.unsubscribe();
                const errorData = await saveResponse.json();
                throw new Error(errorData.error || 'Failed to save subscription');
            }

            setSubscription(pushSubscription);
            setIsSubscribed(true);
            showSnackbar('Successfully subscribed to notifications', 'success');

        } catch (error) {
            console.error('Notification subscription error:', error);
            showSnackbar(error.message, 'error');
        }
    }, [showSnackbar]);

    // Add this effect to check initial service worker registration
    useEffect(() => {
        // Check if service worker is already registered
        if ('serviceWorker' in navigator) {
            navigator.serviceWorker.getRegistration().then(registration => {
                console.log('Existing Service Worker registration:', registration);
            }).catch(error => {
                console.error('Error checking Service Worker registration:', error);
            });
        }
    }, []);

    // Add this effect to check existing subscription on mount
    useEffect(() => {
        const checkExistingSubscription = async () => {
            if ('serviceWorker' in navigator) {
                try {
                    const registration = await navigator.serviceWorker.ready;
                    const existingSubscription = await registration.pushManager.getSubscription();
                    if (existingSubscription) {
                        setSubscription(existingSubscription);
                        setIsSubscribed(true);
                    }
                } catch (error) {
                    console.error('Error checking existing subscription:', error);
                }
            }
        };

        checkExistingSubscription();
    }, []);

    const renderNotificationButton = () => (
        <Fade in={true} timeout={1000}>
            <Button
                variant="contained"
                color={isSubscribed ? "error" : "secondary"}
                onClick={isSubscribed ? unsubscribeFromNotifications : subscribeToNotifications}
                disabled={notificationPermission === 'denied'}
                startIcon={<Bell />}
                sx={{
                    ml: { xs: 0, sm: 2 },
                    mt: { xs: 2, sm: 0 },
                    width: { xs: '100%', sm: 'auto' },
                    background: isSubscribed 
                        ? 'linear-gradient(135deg, #ff4d4d 0%, #ff006e 100%)'
                        : 'linear-gradient(135deg, #3a86ff 0%, #ff006e 100%)',
                    '&:hover': {
                        background: isSubscribed 
                            ? 'linear-gradient(135deg, #ff006e 0%, #ff4d4d 100%)'
                            : 'linear-gradient(135deg, #ff006e 0%, #3a86ff 100%)',
                    },
                    '&:disabled': {
                        background: 'linear-gradient(135deg, #ccc 0%, #999 100%)',
                        opacity: 0.7,
                    }
                }}
            >
                {notificationPermission === 'denied' ? (
                    <Tooltip title="Please enable notifications in your browser settings">
                        <span>Notifications Blocked</span>
                    </Tooltip>
                ) : (
                    isSubscribed ? 'Disable Notifications' : 'Enable Notifications'
                )}
            </Button>
        </Fade>
    );

    const CustomLoadingOverlay = () => (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                height: '100%',
                gap: 2,
            }}
        >
            <CircularProgress />
            <Typography variant="body2" color="text.secondary">
                Caricamento dati...
            </Typography>
        </Box>
    );

    const CustomNoRowsOverlay = () => (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                height: '100%',
                gap: 2,
                p: 3,
            }}
        >
            <Building size={40} color="#ccc" />
            <Typography variant="body2" color="text.secondary">
                Nessuna azienda trovata
            </Typography>
        </Box>
    );

    return (
        <ThemeProvider theme={theme}>
            <CssBaseline />
            <Box 
                sx={{ 
                    minHeight: '100vh', 
                    bgcolor: 'background.default',
                    py: { xs: 4, md: 10 }, 
                    px: { xs: 2, md: 6 },
                    background: 'linear-gradient(135deg, rgba(58, 134, 255, 0.05) 0%, rgba(255, 0, 110, 0.05) 100%)'
                }}
            >
                <Grid container spacing={4}>
                    <Grid item xs={12}>
                        <Box 
                            display="flex" 
                            flexDirection={{ xs: 'column', sm: 'row' }}
                            alignItems={{ xs: 'stretch', sm: 'center' }}
                        >
                            <Fade in={true} timeout={1000}>
                                <Typography variant="h2" gutterBottom={{ xs: true, sm: false }}>
                                    {currentUser === 'superadmin' ? 'Concessionari' : 'Il tuo Concessionario'}
                                </Typography>
                            </Fade>
                        </Box>
                        {currentUser === 'superadmin' && (
                            <Fade in={true} timeout={1500}>
                                <Typography variant="subtitle1" gutterBottom>
                                    <Box component="span" sx={{ color: 'success.main', fontWeight: 'bold' }}>{rows.length}</Box> Aziende totali
                                </Typography>
                            </Fade>
                        )}
                    </Grid>

                    <Grid item xs={12}>
                        {currentUser === 'superadmin' && (
                            <Zoom in={true} timeout={1000}>
                                <Button 
                                    variant="contained" 
                                    color="primary" 
                                    onClick={handleAdd} 
                                    startIcon={<Plus />}
                                    sx={{ mb: 3, background: 'linear-gradient(135deg, #3a86ff 0%, #ff006e 100%)', '&:hover': { background: 'linear-gradient(135deg, #ff006e 0%, #3a86ff 100%)' } }}
                                >
                                    Aggiungi Azienda
                                </Button>
                            </Zoom>
                        )}
                        
                        {isLoading ? (
                            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '50vh' }}>
                                <CircularProgress />
                            </Box>
                        ) : currentUser === 'superadmin' ? (
                            <Fade in={true} timeout={1500}>
                                <Paper 
                                    elevation={3} 
                                    sx={{ 
                                        borderRadius: 2, 
                                        overflow: 'hidden',
                                        transition: 'all 0.3s ease',
                                        '&:hover': {
                                            transform: 'translateY(-4px)',
                                            boxShadow: '0 12px 40px rgba(0,0,0,0.12)',
                                        }
                                    }}
                                >
                                    <DataGrid 
                                        rows={rows} 
                                        columns={columns} 
                                        pageSize={5}
                                        rowsPerPageOptions={[5, 10, 25]}
                                        autoHeight
                                        disableSelectionOnClick
                                        loading={isLoading}
                                        components={{
                                            LoadingOverlay: CustomLoadingOverlay,
                                            NoRowsOverlay: CustomNoRowsOverlay,
                                        }}
                                        sx={{ 
                                            '& .MuiDataGrid-cell:focus': {
                                                outline: 'none',
                                            },
                                            minHeight: 400,
                                        }} 
                                    />
                                </Paper>
                            </Fade>
                        ) : (
                            rows.length > 0 && (
                                <CompanyCard 
                                    company={rows[0]} 
                                    currentUser={currentUser} 
                                    onEdit={handleEdit}
                                />
                            )
                        )}
                    </Grid>
                </Grid>
            </Box>

            <Dialog 
                open={open} 
                onClose={handleClose} 
                maxWidth="md" 
                fullWidth
                TransitionComponent={Zoom}
                TransitionProps={{ timeout: 500 }}
            >
                <DialogTitle>{currentCompany.id ? 'Modifica Azienda' : 'Aggiungi Azienda'}</DialogTitle>
                <DialogContent>
                    <DialogContentText sx={{ mb: 2 }}>
                        Compila i campi sottostanti per {currentCompany.id ? 'modificare' : 'aggiungere'} un'azienda.
                    </DialogContentText>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                autoFocus
                                margin="dense"
                                name="name"
                                label="Nome"
                                type="text"
                                fullWidth
                                value={currentCompany.name}
                                onChange={handleInputChange}
                                error={!!validationErrors.name}
                                helperText={validationErrors.name}
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <Building />
                                        </InputAdornment>
                                    ),
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                margin="dense"
                                name="email"
                                label="Email"
                                type="email"
                                fullWidth
                                value={currentCompany.email}
                                onChange={handleInputChange}
                                error={!!validationErrors.email}
                                helperText={validationErrors.email}
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <Mail />
                                        </InputAdornment>
                                    ),
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                margin="dense"
                                name="link_gdpr"
                                label="Link GDPR"
                                type="url"
                                fullWidth
                                value={currentCompany.link_gdpr}
                                onChange={handleInputChange}
                                error={!!validationErrors.link_gdpr}
                                helperText={validationErrors.link_gdpr}
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <Link />
                                        </InputAdornment>
                                    ),
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                margin="dense"
                                name="link_website"
                                label="Link Website"
                                type="url"
                                fullWidth
                                value={currentCompany.link_website}
                                onChange={handleInputChange}
                                error={!!validationErrors.link_website}
                                helperText={validationErrors.link_website}
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <Link />
                                        </InputAdornment>
                                    ),
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                margin="dense"
                                name="link_marketing_consent"
                                label="Link Marketing Consent"
                                type="url"
                                fullWidth
                                value={currentCompany.link_marketing_consent}
                                onChange={handleInputChange}
                                error={!!validationErrors.link_marketing_consent}
                                helperText={validationErrors.link_marketing_consent}
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <Link />
                                        </InputAdornment>
                                    ),
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                margin="dense"
                                name="sitemap_link"
                                label="Sitemap Link"
                                type="url"
                                fullWidth
                                value={currentCompany.sitemap_link}
                                onChange={handleInputChange}
                                error={!!validationErrors.sitemap_link}
                                helperText={validationErrors.sitemap_link}
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <Link />
                                        </InputAdornment>
                                    ),
                                }}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                margin="dense"
                                name="codice_concessionario"
                                label="Codice concessionario"
                                type="text"
                                fullWidth
                                value={currentCompany.codice_concessionario}
                                onChange={handleInputChange}
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <Building />
                                        </InputAdornment>
                                    ),
                                }}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Input
                                type="file"
                                onChange={handleLogoChange}
                                sx={{ display: 'none' }}
                                id="logo-upload"
                            />
                             <Input
                                type="file"
                                onChange={handleLogoChange}
                                sx={{ display: 'none' }}
                                id="logo-upload"
                            />
                            <label htmlFor="logo-upload">
                                <Button
                                    variant="outlined"
                                    component="span"
                                    startIcon={<Upload />}
                                >
                                    {currentCompany.logo_url ? 'Cambia Logo' : 'Upload Logo'}
                                </Button>
                            </label>
                            {logoFile && (
                                <Typography variant="body2" sx={{ mt: 1 }}>
                                    Nuovo file selezionato: {logoFile.name}
                                </Typography>
                            )}
                            {currentCompany.logo_url && (
                                <Box sx={{ mb: 2, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                    <Typography variant="subtitle1" gutterBottom>Logo attuale:</Typography>
                                    <Box
                                        component="img"
                                        src={currentCompany.logo_url}
                                        alt={`${currentCompany.name} logo`}
                                        sx={{
                                            width: 150,
                                            height: 150,
                                            objectFit: 'contain',
                                            border: '1px solid #ccc',
                                            borderRadius: '4px',
                                            padding: '8px',
                                        }}
                                    />
                                </Box>
                            )}
                        </Grid>
                    </Grid>

                    <Typography variant="h6" sx={{ mt: 4, mb: 2 }}>Locations</Typography>
                    {currentCompany.locations.map((location, index) => (
                        <Accordion key={index} sx={{ mb: 2 }}>
                            <AccordionSummary expandIcon={<ChevronDown />}>
                                <Typography>Location {index + 1}</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Grid container spacing={2}>
                                    <Grid item xs={12}>
                                        <TextField
                                            fullWidth
                                            label="Città"
                                            value={location.citta}
                                            onChange={(e) => handleLocationChange(index, 'citta', e.target.value)}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextField
                                            fullWidth
                                            label="Indirizzo"
                                            value={location.inidirizzo}
                                            onChange={(e) => handleLocationChange(index, 'inidirizzo', e.target.value)}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <TextField
                                            fullWidth
                                            label="CAP"
                                            value={location.cap}
                                            onChange={(e) => handleLocationChange(index, 'cap', e.target.value)}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <TextField
                                            fullWidth
                                            label="Email"
                                            type="email"
                                            value={location.email}
                                            onChange={(e) => handleLocationChange(index, 'email', e.target.value)}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextField
                                            fullWidth
                                            label="Numero di Telefono"
                                            value={location.numero_di_telefono}
                                            onChange={(e) => handleLocationChange(index, 'numero_di_telefono', e.target.value)}
                                        />
                                    </Grid>
                                </Grid>
                                <Button
                                    variant="outlined"
                                    color="error"
                                    onClick={() => removeLocation(index)}
                                    startIcon={<Trash2 />}
                                    sx={{ mt: 2 }}
                                >
                                    Rimuovi Location
                                </Button>
                            </AccordionDetails>
                        </Accordion>
                    ))}
                    <Button
                        variant="outlined"
                        onClick={addLocation}
                        startIcon={<Plus />}
                        sx={{ mt: 2 }}
                    >
                        Aggiungi Location
                    </Button>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} startIcon={<X />}>
                        Annulla
                    </Button>
                    <Button onClick={handleSave} variant="contained" color="primary" startIcon={<Plus />}>
                        {currentCompany.id ? 'Aggiorna' : 'Salva'}
                    </Button>
                </DialogActions>
            </Dialog>

            <Dialog 
                open={confirmDeleteOpen} 
                onClose={() => setConfirmDeleteOpen(false)}
                PaperProps={{
                    style: {
                        borderRadius: '16px',
                    },
                }}
                TransitionComponent={Zoom}
                TransitionProps={{ timeout: 500 }}
            >
                <DialogTitle>Conferma eliminazione</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Sei sicuro di voler eliminare questa azienda? Questa azione non può essere annullata.
                    </DialogContentText>
                    </DialogContent>
                <DialogActions>
                    <Button onClick={() => setConfirmDeleteOpen(false)} startIcon={<X />}>
                        Annulla
                    </Button>
                    <Button onClick={confirmDelete} color="error" variant="contained" startIcon={<Trash2 />}>
                        Conferma
                    </Button>
                </DialogActions>
            </Dialog>

            <AIManagement
                open={openAIDialog}
                onClose={handleCloseAIDialog}
                companyId={selectedCompanyId}
                showSnackbar={showSnackbar}
            />

            <Snackbar 
                open={snackbar.open} 
                autoHideDuration={6000} 
                onClose={() => setSnackbar({ ...snackbar, open: false })}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
            >
                <Alert 
                    onClose={() => setSnackbar({ ...snackbar, open: false })} 
                    severity={snackbar.severity} 
                    sx={{ width: '100%' }}
                    icon={snackbar.severity === 'success' ? <Info /> : undefined}
                >
                    {snackbar.message}
                </Alert>
            </Snackbar>
        </ThemeProvider>
    );
};

export default PaginaAziende;