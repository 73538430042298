import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import {
  Typography,
  Box,
  Card,
  CardContent,
  Button,
  Chip,
  Divider,
  List,
  ListItem,
  ListItemText,
  Snackbar,
  Alert,
  CircularProgress,
  Slide,
  TextField,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Switch,
  FormControlLabel,
  Skeleton,
  Tabs,
  Tab,
  Breadcrumbs,
  Link,
  SpeedDial,
  SpeedDialAction,
  SpeedDialIcon,
  Rating,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { 
  ArrowBack, 
  Phone, 
  Segment, 
  Edit as EditIcon, 
  Add as AddIcon, 
  Message as MessageIcon, 
  Schedule as ScheduleIcon, 
  NoteAdd as NoteAddIcon, 
  Send as SendIcon, 
  Phone as PhoneIcon, 
  WhatsApp as WhatsAppIcon, 
  LocationOn as LocationIcon, 
  Close as CloseIcon,
  Link as LinkIcon
} from '@mui/icons-material';
import axios from 'axios';
import { useAuth } from '../contexts/AuthContext';
import Analytics from '../analytics/Analytics';
import InfiniteScroll from 'react-infinite-scroll-component';
import ReactMarkdown from 'react-markdown';

const API_BASE_URL = 'https://admin.aitomotivelab.com/crm/api';

const api = axios.create({
  baseURL: API_BASE_URL,
});

api.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem('token');
    if (token) {
      config.headers['Authorization'] = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

const StyledCard = styled(Card)(({ theme }) => ({
  marginBottom: theme.spacing(2),
  boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
  transition: 'transform 0.2s ease-in-out, box-shadow 0.2s ease-in-out',
  '&:hover': {
    transform: 'translateY(-2px)',
    boxShadow: '0 6px 12px rgba(0, 0, 0, 0.15)',
  },
  position: 'relative',
  overflow: 'visible',
}));

const IconText = styled(Typography)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  marginBottom: theme.spacing(1),
  padding: theme.spacing(1),
  borderRadius: theme.shape.borderRadius,
  backgroundColor: theme.palette.background.default,
  '& svg': {
    marginRight: theme.spacing(1),
    color: theme.palette.primary.main,
  },
}));

const MobileCard = styled(Card)(({ theme }) => ({
  [theme.breakpoints.down('sm')]: {
    margin: theme.spacing(1),
    padding: theme.spacing(1),
  }
}));

const MobileActionButton = styled(Button)(({ theme }) => ({
  [theme.breakpoints.down('sm')]: {
    width: '100%',
    marginBottom: theme.spacing(1),
  }
}));

const ResponsiveTableContainer = styled(TableContainer)(({ theme }) => ({
  [theme.breakpoints.down('sm')]: {
    '& table': {
      '& td': {
        display: 'block',
        width: '100%',
        textAlign: 'left',
        paddingLeft: '50%',
        position: 'relative',
        '&:before': {
          content: 'attr(data-label)',
          position: 'absolute',
          left: theme.spacing(2),
          fontWeight: 'bold',
        }
      },
      '& th': {
        display: 'none'
      },
      '& tr': {
        marginBottom: theme.spacing(2),
        display: 'block',
        borderBottom: `1px solid ${theme.palette.divider}`
      }
    }
  }
}));

const FormResponsesComponent = ({ formResponses }) => {
  if (!formResponses || formResponses.length === 0) {
    return <Typography>No form responses available.</Typography>;
  }

  const filteredResponses = formResponses[0].field_responses.filter(response => {
    const lowercaseLabel = response.field_label.toLowerCase();
    return !lowercaseLabel.includes('email') && 
           !lowercaseLabel.includes('gdpr') && 
           !lowercaseLabel.includes('marketing');
  });

  return (
    <TableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Field</TableCell>
            <TableCell>Value</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {filteredResponses.map((response) => (
            <TableRow key={response.id}>
              <TableCell>{response.field_label}</TableCell>
              <TableCell>{response.value}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

const MobileContainer = styled(Box)(({ theme }) => ({
  [theme.breakpoints.down('sm')]: {
    maxWidth: '100%',
    margin: '0 auto',
    overflowX: 'hidden',
    backgroundColor: theme.palette.background.default,
    minHeight: '100vh',
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    overflowY: 'auto'
  }
}));

const ClientHeader = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: theme.spacing(2),
  marginBottom: theme.spacing(3),
  padding: theme.spacing(2),
  backgroundColor: theme.palette.background.paper,
  borderRadius: theme.shape.borderRadius,
  boxShadow: theme.shadows[1],
  [theme.breakpoints.down('sm')]: {
    flexDirection: 'column',
    alignItems: 'flex-start',
  }
}));

const ClientAvatar = styled(Box)(({ theme }) => ({
  width: 80,
  height: 80,
  borderRadius: '50%',
  backgroundColor: theme.palette.primary.main,
  color: theme.palette.primary.contrastText,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  fontSize: '2rem',
}));

const LoadingSkeleton = styled(Box)(({ theme }) => ({
  '& .MuiSkeleton-root': {
    backgroundColor: theme.palette.background.paper,
  }
}));

const FloatingActionButton = styled(SpeedDial)(({ theme }) => ({
  position: 'fixed',
  bottom: theme.spacing(2),
  right: theme.spacing(2),
}));

const ContactActionButton = styled(Button)(({ theme }) => ({
  margin: theme.spacing(1),
  borderRadius: theme.shape.borderRadius,
  textTransform: 'none',
  padding: theme.spacing(1, 2),
  '&:hover': {
    transform: 'translateY(-2px)',
    boxShadow: theme.shadows[4],
  },
  transition: 'all 0.2s ease-in-out',
}));

const ContactIconButton = styled(IconButton)(({ theme }) => ({
  [theme.breakpoints.down('sm')]: {
    width: '40px',
    height: '40px',
    '& .MuiSvgIcon-root': {
      fontSize: '1.5rem',
    }
  }
}));

const ClientDetailsSkeleton = () => (
  <LoadingSkeleton>
    <Skeleton variant="rectangular" height={200} sx={{ mb: 2, borderRadius: 1 }} />
    <Grid container spacing={3}>
      <Grid item xs={12} md={8}>
        <Skeleton variant="rectangular" height={400} sx={{ borderRadius: 1 }} />
      </Grid>
      <Grid item xs={12} md={4}>
        <Skeleton variant="rectangular" height={200} sx={{ mb: 2, borderRadius: 1 }} />
        <Skeleton variant="rectangular" height={200} sx={{ borderRadius: 1 }} />
      </Grid>
    </Grid>
  </LoadingSkeleton>
);

const MessageList = styled(List)(({ theme }) => ({
  flexGrow: 1,
  overflowY: 'auto',
  padding: theme.spacing(1),
  margin: 0,
  maxHeight: '500px',
  '&::-webkit-scrollbar': {
    width: '8px',
    backgroundColor: 'transparent',
  },
  '&::-webkit-scrollbar-track': {
    background: 'transparent',
  },
  '&::-webkit-scrollbar-thumb': {
    background: theme.palette.primary.main,
    borderRadius: '4px',
    opacity: 0,
    transition: 'opacity 0.3s ease-in-out',
  },
  '&:hover::-webkit-scrollbar-thumb': {
    opacity: 1,
  },
}));

const MessagePaper = styled(Paper)(({ theme, isBot }) => ({
  padding: theme.spacing(1.5),
  marginTop: theme.spacing(1),
  backgroundColor: isBot ? theme.palette.primary.light : theme.palette.background.paper,
  color: isBot ? theme.palette.primary.contrastText : theme.palette.text.primary,
  borderRadius: '12px',
  maxWidth: '80%',
  marginLeft: isBot ? 0 : 'auto',
  marginRight: isBot ? 'auto' : 0,
  wordBreak: 'break-word',
}));

const ChatSection = ({ clientId, setSnackbar }) => {
  const [chats, setChats] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedChat, setSelectedChat] = useState(null);
  const [dialogOpen, setDialogOpen] = useState(false);

  useEffect(() => {
    fetchChats();
  }, [clientId]);

  const fetchChats = async () => {
    try {
      const response = await axios.get(`${API_BASE_URL}/Clients/client_chats/?client_id=${clientId}`);
      setChats(response.data);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching chats:', error);
      setLoading(false);
    }
  };

  const handleChatClick = (chat) => {
    setSelectedChat(chat);
    setDialogOpen(true);
  };

  const handleCloseDialog = () => {
    setDialogOpen(false);
    setSelectedChat(null);
  };

  const handleCopyChatLink = (chatId) => {
    const baseUrl = window.location.origin;
    const chatUrl = `${baseUrl}/chat/${chatId}`;
    navigator.clipboard.writeText(chatUrl).then(() => {
      setSnackbar({ 
        open: true, 
        message: 'Chat link copied to clipboard!', 
        severity: 'success' 
      });
    }).catch(err => {
      console.error('Failed to copy chat link:', err);
      setSnackbar({ 
        open: true, 
        message: 'Failed to copy chat link', 
        severity: 'error' 
      });
    });
  };

  if (loading) {
    return <CircularProgress />;
  }

  return (
    <>
      <StyledCard>
        <CardContent>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
            <Typography variant="h6">Chat History</Typography>
          </Box>
          {chats.length === 0 ? (
            <Typography variant="body2" color="textSecondary">No chat history available</Typography>
          ) : (
            <MessageList>
              {chats.map((chat) => (
                <Box key={chat.id} sx={{ mb: 2 }}>
                  <Typography variant="caption" color="textSecondary" sx={{ display: 'block', mb: 1 }}>
                    {new Date(chat.created_at).toLocaleString()}
                  </Typography>
                  <Box sx={{ mb: 1 }}>
                    <Typography variant="body2">
                      Platform: {chat.platform}
                    </Typography>
                    {chat.summary && (
                      <Typography variant="body2">
                        Summary: {chat.summary}
                      </Typography>
                    )}
                    {chat.client_request && (
                      <Typography variant="body2">
                        Request: {chat.client_request}
                      </Typography>
                    )}
                  </Box>
                  <Box sx={{ display: 'flex', gap: 1 }}>
                    <Button
                      variant="outlined"
                      size="small"
                      onClick={() => handleChatClick(chat)}
                      startIcon={<MessageIcon />}
                    >
                      View Chat
                    </Button>
                    <Button
                      variant="outlined"
                      size="small"
                      onClick={() => handleCopyChatLink(chat.unique_id)}
                      startIcon={<LinkIcon />}
                      color="info"
                    >
                      Copy Link
                    </Button>
                  </Box>
                  <Divider sx={{ mt: 2 }} />
                </Box>
              ))}
            </MessageList>
          )}
        </CardContent>
      </StyledCard>

      <Dialog 
        open={dialogOpen} 
        onClose={handleCloseDialog}
        maxWidth="md"
        fullWidth
      >
        <DialogTitle>
          Chat Details
          <IconButton
            aria-label="close"
            onClick={handleCloseDialog}
            sx={{ position: 'absolute', right: 8, top: 8 }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          {selectedChat && (
            <iframe
              src={`/chat/${selectedChat.unique_id}`}
              style={{ width: '100%', height: '500px', border: 'none' }}
              title="Chat Frame"
            />
          )}
        </DialogContent>
      </Dialog>
    </>
  );
};

const LocationCard = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'flex-start',
  gap: theme.spacing(1),
  padding: theme.spacing(2),
  backgroundColor: theme.palette.background.default,
  borderRadius: theme.shape.borderRadius,
  border: `1px solid ${theme.palette.divider}`,
  '& .location-icon': {
    color: theme.palette.primary.main,
    marginTop: '2px'
  },
  '& .location-content': {
    flex: 1
  }
}));

const ClientDetailsPage = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const { userRole, appAccess } = useAuth();
  const permissions = appAccess['crm_app'] || {};
  const canEdit = permissions.can_edit || userRole === 'superadmin';
  const canCreate = permissions.can_create || userRole === 'superadmin';

  const [client, setClient] = useState(null);
  const [feedback, setFeedback] = useState([]);
  const [loading, setLoading] = useState(true);
  const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'success' });
  const [editMode, setEditMode] = useState(false);
  const [editedClient, setEditedClient] = useState(null);
  const [feedbackDialogOpen, setFeedbackDialogOpen] = useState(false);
  const [newFeedback, setNewFeedback] = useState({ rating: 5, comment: '' });
  const [activeTab, setActiveTab] = useState(0);
  const [page, setPage] = useState(1);

  useEffect(() => {
    fetchClientDetails();
  }, [id]);

  const fetchClientDetails = async () => {
    try {
      const clientResponse = await api.get(`/Clients/${id}/`);
      setClient(clientResponse.data);
      setEditedClient(clientResponse.data);

      const feedbackResponse = await api.get(`/Client-feedback/?client=${id}`);
      setFeedback(feedbackResponse.data);

      setLoading(false);
    } catch (error) {
      console.error('Error fetching client details:', error);
      handleApiError(error);
      setLoading(false);
    }
  };

  const handleApiError = (error) => {
    if (error.response && error.response.status === 401) {
      setSnackbar({ open: true, message: 'Authentication failed. Please log in again.', severity: 'error' });
      navigate('/login');
    } else {
      setSnackbar({ open: true, message: 'An error occurred. Please try again.', severity: 'error' });
    }
  };

  const handleSnackbarClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackbar({ ...snackbar, open: false });
  };

  const handleEditClick = () => {
    setEditMode(true);
  };

  const handleCancelEdit = () => {
    setEditMode(false);
    setEditedClient(client);
  };

  const handleSaveEdit = async () => {
    try {
        const submissionData = {
            first_name: editedClient.first_name || '',
            last_name: editedClient.last_name || '',
            number: editedClient.number || '',
            label_segmentation: Array.isArray(editedClient.label_segmentation) 
                ? editedClient.label_segmentation 
                : [],
            introduzione: editedClient.introduzione || '',
        };

        const response = await api.put(`/Clients/${id}/`, submissionData);
        
        if (response.status === 200) {
            setClient(response.data);
            setEditMode(false);
            setSnackbar({ 
                open: true, 
                message: 'Client updated successfully!', 
                severity: 'success' 
            });
        }
    } catch (error) {
        console.error('Error updating client:', error);
        handleApiError(error);
    }
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setEditedClient(prev => ({
        ...prev,
        [name]: name === 'label_segmentation' ? 
                (Array.isArray(value) ? value : [value]) :
                value
    }));
  };

  const handleAddFeedback = () => {
    setFeedbackDialogOpen(true);
  };

  const handleFeedbackChange = (event) => {
    const { name, value } = event.target;
    setNewFeedback((prev) => ({ ...prev, [name]: name === 'rating' ? parseInt(value, 10) : value }));
  };

  const handleFeedbackSubmit = async () => {
    try {
      const response = await api.post('/Client-feedback/', {
        ...newFeedback,
        clients: parseInt(id, 10),
      });
      setFeedback([response.data, ...feedback]);
      setFeedbackDialogOpen(false);
      setNewFeedback({ rating: 5, comment: '' });
      setSnackbar({ open: true, message: 'Feedback added successfully!', severity: 'success' });
    } catch (error) {
      console.error('Error adding feedback:', error);
      handleApiError(error);
    }
  };

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  const handleCopyLink = () => {
    const url = window.location.href;
    navigator.clipboard.writeText(url).then(() => {
      setSnackbar({ 
        open: true, 
        message: 'Link copied to clipboard!', 
        severity: 'success' 
      });
    }).catch(err => {
      console.error('Failed to copy link:', err);
      setSnackbar({ 
        open: true, 
        message: 'Failed to copy link', 
        severity: 'error' 
      });
    });
  };

  if (loading) {
    return <ClientDetailsSkeleton />;
  }

  if (!client) {
    return <Typography>Client not found.</Typography>;
  }

  const actions = [
    { 
      icon: <PhoneIcon />, 
      name: 'Call', 
      onClick: () => {
        if (client.number) {
          window.location.href = `tel:${client.number}`;
        }
      },
      color: 'success.main'
    },
    { 
      icon: <WhatsAppIcon />, 
      name: 'WhatsApp', 
      onClick: () => {
        if (client.number) {
          const formattedNumber = client.number.replace(/[\s()-]/g, '');
          window.open(`https://wa.me/${formattedNumber}`, '_blank');
        }
      },
      color: '#25D366'
    },
    { icon: <MessageIcon />, name: 'Send Message', onClick: () => {} },
    { icon: <ScheduleIcon />, name: 'Schedule Meeting', onClick: () => {} },
    { icon: <NoteAddIcon />, name: 'Add Note', onClick: () => {} },
    { 
      icon: <LinkIcon />, 
      name: 'Copy Link', 
      onClick: handleCopyLink,
      color: 'info.main'
    },
  ];

  const tabs = [
    { label: "Overview", value: 0 },
    { label: "Chats", value: 1 },
    { label: "Analytics", value: 2 }
  ];

  return (
    <MobileContainer>
      <Box sx={{ maxWidth: '1200px', padding: { xs: 2, sm: 3 }, margin: 'auto' }}>
        <Breadcrumbs sx={{ mb: 2 }}>
          <Link color="inherit" href="/dashboard">
            Dashboard
          </Link>
          <Link color="inherit" href="/clients">
            Clients
          </Link>
          <Typography color="textPrimary">
            {client.first_name} {client.last_name}
          </Typography>
        </Breadcrumbs>

        <MobileActionButton
          onClick={() => navigate('/clients')}
          variant="outlined"
          startIcon={<ArrowBack />}
          sx={{ mb: 2 }}
        >
          Back to Clients List
        </MobileActionButton>

        <ClientHeader>
          <ClientAvatar>
            {client.first_name?.[0]}{client.last_name?.[0]}
          </ClientAvatar>
          <Box sx={{ flex: 1 }}>
            <Typography variant="h4" gutterBottom>
              {client.first_name} {client.last_name}
            </Typography>
            <Box sx={{ display: 'flex', gap: 1, flexWrap: 'wrap' }}>
              {client.label_segmentation_names.map((segment, index) => (
                <Chip
                  key={index}
                  label={segment}
                  color="primary"
                  size="small"
                  variant="outlined"
                />
              ))}
            </Box>
            {client.number && (
              <Box sx={{ 
                display: { xs: 'none', sm: 'flex' }, 
                gap: 2, 
                mt: 2,
                flexWrap: 'wrap',
                alignItems: 'center'
              }}>
                <Typography variant="body1" sx={{ 
                  display: 'flex', 
                  alignItems: 'center',
                  gap: 1,
                  color: 'text.secondary'
                }}>
                  <PhoneIcon fontSize="small" />
                  {client.number}
                </Typography>
                <ContactActionButton
                  variant="contained"
                  color="success"
                  startIcon={<PhoneIcon />}
                  onClick={() => window.location.href = `tel:${client.number}`}
                  size="small"
                >
                  Call
                </ContactActionButton>
                <ContactActionButton
                  variant="contained"
                  sx={{
                    bgcolor: '#25D366',
                    '&:hover': {
                      bgcolor: '#1fa855'
                    }
                  }}
                  startIcon={<WhatsAppIcon />}
                  onClick={() => {
                    const formattedNumber = client.number.replace(/[\s()-]/g, '');
                    window.open(`https://wa.me/${formattedNumber}`, '_blank');
                  }}
                  size="small"
                >
                  WhatsApp
                </ContactActionButton>
              </Box>
            )}
          </Box>
          {canEdit && (
            <Button
              variant="contained"
              startIcon={<EditIcon />}
              onClick={handleEditClick}
              sx={{ alignSelf: 'flex-start' }}
            >
              Edit Profile
            </Button>
          )}
        </ClientHeader>

        <Tabs 
          value={activeTab} 
          onChange={handleTabChange} 
          sx={{ mb: 3 }}
          variant="scrollable"
          scrollButtons="auto"
        >
          <Tab label="Overview" />
          <Tab label="Chats" />
          <Tab label="Analytics" />
        </Tabs>

        {activeTab === 0 && (
          <Grid container spacing={3}>
            <Grid item xs={12} md={8}>
              <StyledCard>
                <CardContent>
                  {editMode ? (
                    <Grid container spacing={2}>
                      <Grid item xs={12} sm={6}>
                        <TextField
                          fullWidth
                          label="First Name"
                          name="first_name"
                          value={editedClient.first_name}
                          onChange={handleInputChange}
                          disabled={!canEdit}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <TextField
                          fullWidth
                          label="Last Name"
                          name="last_name"
                          value={editedClient.last_name}
                          onChange={handleInputChange}
                          disabled={!canEdit}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <TextField
                          fullWidth
                          label="Phone"
                          name="number"
                          value={editedClient.number}
                          onChange={handleInputChange}
                          disabled={!canEdit}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <TextField
                          fullWidth
                          multiline
                          rows={4}
                          label="Sede"
                          name="introduzione"
                          value={editedClient.introduzione}
                          onChange={handleInputChange}
                          disabled={!canEdit}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <Button onClick={handleSaveEdit} variant="contained" color="primary" sx={{ mr: 1 }} disabled={!canEdit}>
                          Save
                        </Button>
                        <Button onClick={handleCancelEdit} variant="outlined">
                          Cancel
                        </Button>
                      </Grid>
                    </Grid>
                  ) : (
                    <>
                      <Typography variant="h5" gutterBottom>
                        {client.first_name} {client.last_name}
                      </Typography>
                      <IconText variant="body1">
                        <Phone />
                        {client.number}
                      </IconText>
                      <IconText variant="body1">
                        <Segment />
                        Segment: {client.label_segmentation_names.map((segment, index) => (
                          <Chip key={index} label={segment} color="primary" sx={{ ml: 1 }} />
                        ))}
                      </IconText>
                      <LocationCard>
                        <LocationIcon className="location-icon" />
                        <Box className="location-content">
                          <Typography variant="subtitle2" color="primary" gutterBottom>
                            Sede preferita
                          </Typography>
                          <Typography variant="body2">
                            {client.location || 'No location specified'}
                          </Typography>
                        </Box>
                      </LocationCard>
                    </>
                  )}
                </CardContent>
              </StyledCard>
            </Grid>
            
            <Grid item xs={12} md={4}>
              <StyledCard>
                <CardContent>
                  <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
                    <Typography variant="h6">Feedback</Typography>
                    {canCreate && (
                      <Button
                        variant="outlined"
                        size="small"
                        startIcon={<AddIcon />}
                        onClick={handleAddFeedback}
                      >
                        Add Feedback
                      </Button>
                    )}
                  </Box>
                  {feedback.length === 0 ? (
                    <Typography variant="body2" color="textSecondary">
                      No feedback available
                    </Typography>
                  ) : (
                    <Box sx={{ maxHeight: 400, overflowY: 'auto' }}>
                      {feedback.map((item, index) => (
                        <Box key={index} sx={{ mb: 2 }}>
                          <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
                            <Rating value={item.rating} readOnly size="small" />
                            <Typography variant="caption" color="textSecondary" sx={{ ml: 1 }}>
                              {new Date(item.created_at).toLocaleDateString()}
                            </Typography>
                          </Box>
                          <Typography variant="body2">{item.comment}</Typography>
                          {index < feedback.length - 1 && <Divider sx={{ my: 2 }} />}
                        </Box>
                      ))}
                    </Box>
                  )}
                </CardContent>
              </StyledCard>
            </Grid>
          </Grid>
        )}

        {activeTab === 1 && (
          <ChatSection 
            clientId={id} 
            setSnackbar={setSnackbar}
          />
        )}

        {activeTab === 2 && (
          <StyledCard>
            <CardContent>
              <Typography variant="h6" gutterBottom>Analytics Overview</Typography>
              <Analytics 
                companyId={client.company} 
                client={client.id} 
                detailed={true} 
              />
            </CardContent>
          </StyledCard>
        )}

        <FloatingActionButton
          ariaLabel="Quick Actions"
          icon={<SpeedDialIcon />}
        >
          {actions.map((action) => (
            <SpeedDialAction
              key={action.name}
              icon={action.icon}
              tooltipTitle={action.name}
              onClick={action.onClick}
              sx={action.color ? {
                '& .MuiSpeedDialAction-fab': {
                  bgcolor: action.color,
                  color: 'white',
                  '&:hover': {
                    bgcolor: action.color,
                    opacity: 0.9
                  }
                }
              } : {}}
            />
          ))}
        </FloatingActionButton>

        <Dialog open={feedbackDialogOpen} onClose={() => setFeedbackDialogOpen(false)}>
          <DialogTitle>Add New Feedback for {client.first_name} {client.last_name}</DialogTitle>
          <DialogContent>
            <TextField
              name="rating"
              label="Rating"
              type="number"
              value={newFeedback.rating}
              onChange={handleFeedbackChange}
              fullWidth
              margin="normal"
              inputProps={{ min: 1, max: 5 }}
            />
            <TextField
              name="comment"
              label="Comment"
              value={newFeedback.comment}
              onChange={handleFeedbackChange}
              fullWidth
              margin="normal"
              multiline
              rows={4}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setFeedbackDialogOpen(false)}>Cancel</Button>
            <Button onClick={handleFeedbackSubmit} color="primary" variant="contained" disabled={!canCreate}>
              Submit</Button>
          </DialogActions>
        </Dialog>

        <Snackbar
          open={snackbar.open}
          autoHideDuration={6000}
          onClose={handleSnackbarClose}
          TransitionComponent={(props) => <Slide {...props} direction="up" />}
        >
          <Alert onClose={handleSnackbarClose} severity={snackbar.severity} sx={{ width: '100%' }}>
            {snackbar.message}
          </Alert>
        </Snackbar>
      </Box>
    </MobileContainer>
  );
};

export default ClientDetailsPage;