import React from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, IconButton } from '@mui/material';
import { Delete as DeleteIcon, Edit as EditIcon } from '@mui/icons-material';
import { useAuth } from '../../../contexts/AuthContext';

const AvailabilityList = ({ availabilities, handleEditAvailability, handleDeleteAvailability, dayOfWeekOptions, locations = [] }) => {
  const { userRole, appAccess } = useAuth(); // Access user role and permissions
  const permissions = appAccess['booking_settings'] || {}; // Use booking_settings for permissions
  const setting_canEdit = permissions.can_edit || userRole === 'superadmin'; // Edit permission
  const setting_canDelete = permissions.can_delete || userRole === 'superadmin'; // Delete permission

  const handleEdit = (availability) => {
    console.log('Original availability:', availability);
    
    const formattedAvailability = {
      ...availability,
      location: Array.isArray(availability.location) 
        ? availability.location 
        : (typeof availability.location === 'string' 
          ? availability.location.split(',').map(id => parseInt(id, 10))
          : []),
      day_of_week: [availability.day_of_week.toString()]
    };
    
    console.log('Formatted availability:', formattedAvailability);
    handleEditAvailability(formattedAvailability);
  };

  return (
    <TableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Day of Week</TableCell>
            <TableCell>Start Time</TableCell>
            <TableCell>End Time</TableCell>
            <TableCell>Max Slots</TableCell>
            <TableCell>Cities</TableCell>
            <TableCell>Actions</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {availabilities.map((availability) => (
            <TableRow key={availability.id}>
              <TableCell>
                {dayOfWeekOptions.find((option) => option.value === availability.day_of_week)?.label}
              </TableCell>
              <TableCell>{availability.start_time}</TableCell>
              <TableCell>{availability.end_time}</TableCell>
              <TableCell>{availability.max_slots}</TableCell>
              <TableCell>
                {Array.isArray(availability.location) 
                  ? availability.location
                      .map(locationId => {
                        const location = locations.find(l => l.id === locationId);
                        return location ? location.citta : '';
                      })
                      .join(', ')
                  : ''}
              </TableCell>
              <TableCell>
                {setting_canEdit && (
                  <IconButton onClick={() => handleEdit(availability)} color="primary">
                    <EditIcon />
                  </IconButton>
                )}
                {setting_canDelete && (
                  <IconButton onClick={() => handleDeleteAvailability(availability.id)} color="error">
                    <DeleteIcon />
                  </IconButton>
                )}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default AvailabilityList;
